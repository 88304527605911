import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { Box } from "@mui/system";
import {
  Button,
  Typography,
  Card,
  CardContent,
  TextField,
  CircularProgress,
  Alert,
} from "@mui/material";

import {
  ADD_SUB_PACKAGE_VARIANT,
  ALL_SUB_PACKAGE_VARIANT,
} from "../../gql/subscriptionpackageVariant";

const CreateSubPackageVariant = ({ handleClose, packageId }) => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [showAlert, setShowAlert] = useState({ message: "", isError: false });

  const [progress, setProgress] = useState(0);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const [createVideo] = useMutation(ADD_SUB_PACKAGE_VARIANT, {
    onError: (error) => {
      console.log("error : ", error);
      alert("Sub Package Variant Upload Error");
      setLoading(false);
      alert("Upload Error");
    },
    onCompleted: (result) => {
      setValues({});
      setErrors({});

      setLoading(false);
      // videoAlert("New Video have been created.");
      alert("New Sub Package Variant has been Created");
      handleClose();
    },
    refetchQueries: [ALL_SUB_PACKAGE_VARIANT],
  });

  const handleCreate = async () => {
    setLoading(true);
    setErrors({});
    let isErrorExit = false;
    let errorObject = {};
    if (!values.title_en) {
      errorObject.title_en = "title_en  is required.";
      isErrorExit = true;
    }
    if (!values.price) {
      errorObject.price = "price is required.";
      isErrorExit = true;
    }
    if (!values.title_mm) {
      errorObject.title_mm = "title_mm is required.";
      isErrorExit = true;
    }
    if (!values.durationInDays) {
      errorObject.durationInDays = "durationInDays is required.";
      isErrorExit = true;
    }
    if (!values.description_en) {
      errorObject.description_en = "description_en is required.";
      isErrorExit = true;
    }
    if (!values.description_mm) {
      errorObject.description_mm = "description_mm is required.";
      isErrorExit = true;
    }
    if (!values.original_price) {
      errorObject.original_price = "original_price is required.";
      isErrorExit = true;
    }
    if (!values.discount_percentage) {
      errorObject.discount_percentage = "discount_percentage is required.";
      isErrorExit = true;
    }

    if (isErrorExit) {
      setErrors({ ...errorObject });
      setLoading(false);
      return;
    }

    try {
      await createVideo({
        variables: {
          addSubPackageVariant: {
            title_en: values.title_en,
            title_mm: values.title_mm,
            price: parseInt(values.price),
            original_price: values?.original_price
              ? parseInt(values.original_price)
              : 0,
            description_en: values.description_en,
            description_mm: values.description_mm,
            discount_percentage: values?.discount_percentage
              ? parseInt(values.discount_percentage)
              : 0,
            durationInDays: parseInt(values.durationInDays),
            subscription_package_id: packageId,
          },
        },
      });
    } catch (error) {
      console.log("error : ", error);
    }
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          color: "black",
          // width: "94.5vw",
          bgcolor: "#cecece",
          borderTopRightRadius: 10,
          borderTopLeftRadius: 10,
          py: 2,
        }}
      >
        <Typography variant="h5" component="h2" color="black" sx={{ mx: 4 }}>
          Create Subscription Pakcage Variant
        </Typography>
        <Button
          onClick={handleClose}
          variant="contained"
          color="error"
          sx={{ mx: 4 }}
        >
          Close
        </Button>
      </Box>
      <Card
        sx={{
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }}
      >
        <CardContent>
          {/* list items */}
          <div
            className="grid--2--cols grid-item"
            style={{
              columnGap: "5rem",
              rowGap: "2rem",
              paddingLeft: "2rem",
              paddingRight: "2rem",
            }}
          >
            {/* title en */}
            <TextField
              id="title_en"
              label="Title En"
              // value={values.video_package_name}
              onChange={handleChange("title_en")}
              error={errors.title_en ? true : false}
              helperText={errors.title_en}
            />
            {/* title mm*/}
            <TextField
              id="title_mm"
              label="Title MM"
              // value={values.video_package_name}
              onChange={handleChange("title_mm")}
              error={errors.title_mm ? true : false}
              helperText={errors.title_mm}
            />
            {/*description */}
            <TextField
              id="description_en"
              label="Description En"
              value={values.description_en}
              onChange={handleChange("description_en")}
              error={errors.description_en ? true : false}
              helperText={errors.description_en}
            />
            {/*description mm */}
            <TextField
              id="description_mm"
              label="Description MM"
              value={values.description_mm}
              onChange={handleChange("description_mm")}
              error={errors.description_mm ? true : false}
              helperText={errors.description_mm}
            />
            {/* duration */}
            <TextField
              type="number"
              id="durationInDays"
              label="Duration InDays"
              value={values.durationInDays}
              onChange={handleChange("durationInDays")}
              error={errors.durationInDays ? true : false}
              helperText={errors.durationInDays}
            />
            {/* price */}
            <TextField
              type="number"
              id="price"
              label="Price (Actual Sale Price)"
              value={values.price}
              onChange={handleChange("price")}
              error={errors.price ? true : false}
              helperText={errors.price}
            />
            {/* original price */}
            <TextField
              type="number"
              id="price"
              label="Original Price (Before Discount)"
              value={values.original_price}
              onChange={handleChange("original_price")}
              error={errors.original_price ? true : false}
              helperText={errors.original_price}
            />
            {/* discount percentage*/}
            <TextField
              type="number"
              id="discount_percentage"
              label="Discount Percentage"
              value={values.discount_percentage}
              onChange={handleChange("discount_percentage")}
              error={errors.discount_percentage ? true : false}
              helperText={errors.discount_percentage}
            />
          </div>
        </CardContent>

        <Box className="btn_end">
          <Button
            variant="contained"
            className="progress"
            size="large"
            sx={{ height: 50, width: 100 }}
            {...(loading && { loading: true })}
            onClick={handleCreate}
            //style={{ position: "absolute", top: "10px", left: "2px" }}
          >
            {loading ? (
              <CircularProgress
                color="warning"
                value={progress}
                text={`${progress}%`}
              />
            ) : (
              "Create"
            )}
          </Button>
        </Box>
      </Card>

      {showAlert.message && !showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="success"
        >
          {showAlert.message}
        </Alert>
      )}
      {showAlert.message && showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="error"
        >
          {showAlert.message}
        </Alert>
      )}
    </div>
  );
};
export default CreateSubPackageVariant;
