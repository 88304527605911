import { gql } from "@apollo/client";
export const ALL_EXE_ROUTINE = gql`
  query exeRoutine(
    $limit: Int!
    $offset: Int!
    $search: String!
    $fk_routine_id: uuid!
  ) {
    exercise_routine(
      limit: $limit
      offset: $offset
      order_by: { updated_at: desc }
      where: {
        _and: {
          fk_routine_id: { _eq: $fk_routine_id }
          exercise_routine_name: { _ilike: $search }
        }
      }
    ) {
      id
      exercise_routine_name
      created_at
      routine {
        routine_name
        id
      }
      video_list {
        id
        video_package_name
        video_id
        exercise_type {
          exercise_type_name
          id
        }
      }
    }
    exercise_routine_aggregate(
      where: {
        fk_routine_id: { _eq: $fk_routine_id }
        exercise_routine_name: { _ilike: $search }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

//get by pk
export const EXE_ROUTINE_BY_PK = gql`
  query aa($id: uuid!) {
    exercise_routine_by_pk(id: $id) {
      id
      exercise_routine_name
      created_at
      video_list {
        video_package_name
        id
        video_id
      }
      routine {
        routine_name
        id
        fk_plan_id
      }
      fk_video_id
      fk_routine_id
    }
  }
`;
//create exe routine
export const CREATE_EXE_ROUTINE = gql`
  mutation ee(
    $exercise_routine_name: String!
    $fk_routine_id: uuid!
    $fk_video_id: Int!
  ) {
    insert_exercise_routine(
      objects: {
        exercise_routine_name: $exercise_routine_name
        fk_routine_id: $fk_routine_id
        fk_video_id: $fk_video_id
      }
    ) {
      returning {
        exercise_routine_name
        id
      }
    }
  }
`;

//delete exe routine
export const DELETE_EXE_ROUTINE = gql`
  mutation aa($id: uuid!) {
    delete_exercise_routine_by_pk(id: $id) {
      id
    }
  }
`;

//update exe routine
export const UPDATE_EXE_ROUTINE = gql`
  mutation UPDATE_EXE_ROUTINE_BY_PK(
    $id: uuid!
    $exercise_routine_name: String!
    $fk_routine_id: uuid!
    $fk_video_id: Int!
  ) {
    update_exercise_routine_by_pk(
      pk_columns: { id: $id }
      _set: {
        exercise_routine_name: $exercise_routine_name
        fk_routine_id: $fk_routine_id
        fk_video_id: $fk_video_id
      }
    ) {
      exercise_routine_name
      id
    }
  }
`;
//get exercise routine for video
export const EXERCISE_VIDEO_ROUTINE = gql`
  query aa($video_id: Int!) {
    exercise_routine(where: { video_list: { video_id: { _eq: $video_id } } }) {
      id
      exercise_routine_name
      created_at
      routine {
        id
        routine_name
        plan {
          plan_name
          id
        }
      }

      video_list {
        id
        video_id
      }
    }
    exercise_routine_aggregate(
      where: { video_list: { video_id: { _eq: $video_id } } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

//add to top
export const POSITIION = gql`
  mutation MyMutation($id: uuid!, $updated_at: timestamptz!) {
    update_exercise_routine_by_pk(
      pk_columns: { id: $id }
      _set: { updated_at: $updated_at }
    ) {
      id
    }
  }
`;
