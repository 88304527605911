import { gql } from "@apollo/client";

// get routine data
export const ALL_PLANS = gql`
  query MyQuery(
    $limit: Int!
    $offset: Int!
    $search: String!
    $pending: Boolean!
  ) {
    plans(
      limit: $limit
      offset: $offset
      where: { plan_name: { _ilike: $search }, pending: { _eq: $pending } }
      order_by: { updated_at: desc }
    ) {
      description
      description_mm
      exercise_target {
        target_name
        id
      }
      fk_exercise_target_id
      fk_experience_level_id
      fk_user_subscription_level_id
      id
      plan_name
      image_url
      routine_days_in_a_week
      routines {
        routine_name
      }
      experience_level {
        name
        id
      }
      updated_at
      created_at
      user_subscription_level {
        subscription_type
      }
    }
    plans_aggregate(
      where: { plan_name: { _ilike: $search }, pending: { _eq: $pending } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

//get exe routine by pk
export const PLAN_PK = gql`
  query MyQuery($id: uuid!) {
    plans_by_pk(id: $id) {
      created_at
      description
      description_mm
      exercise_target {
        target_name
        id
      }
      experience_level {
        id
        name
      }
      image_url
      id
      fk_user_subscription_level_id
      fk_experience_level_id
      fk_exercise_target_id
      pending
      plan_name
      routine_days_in_a_week
      updated_at
      routines {
        routine_name
        id
      }
      user_subscription_level {
        subscription_type
      }
    }
  }
`;

// Remove routine data
export const DELETE_PLAN = gql`
  mutation MyMutation($id: uuid!) {
    delete_plans_by_pk(id: $id) {
      id
    }
  }
`;

// Create Routine
export const CREATE_EXE_ROUTINE = gql`
  mutation MyMutation(
    $plan_name: String!
    $fk_exercise_target_id: uuid!
    $description: String!
    $description_mm: String!
    $fk_user_subscription_level_id: Int!
    $fk_experience_level_id: uuid!
    $image_url: String!
    $routine_days_in_a_week: Int!
  ) {
    insert_plans_one(
      object: {
        description: $description
        description_mm: $description_mm
        fk_experience_level_id: $fk_experience_level_id
        fk_exercise_target_id: $fk_exercise_target_id
        fk_user_subscription_level_id: $fk_user_subscription_level_id
        image_url: $image_url
        plan_name: $plan_name
        routine_days_in_a_week: $routine_days_in_a_week
      }
    ) {
      description
      description_mm
      created_at
      id
    }
  }
`;

export const UPDATE_PLAN = gql`
  mutation UPDATE_PLANS_BY_PK(
    $id: uuid!
    $plan_name: String!
    $fk_exercise_target_id: uuid!
    $description: String!
    $description_mm: String!
    $fk_user_subscription_level_id: Int!
    $fk_experience_level_id: uuid!
    $image_url: String!
    $routine_days_in_a_week: Int!
  ) {
    update_plans_by_pk(
      pk_columns: { id: $id }
      _set: {
        description: $description
        description_mm: $description_mm
        fk_experience_level_id: $fk_experience_level_id
        fk_exercise_target_id: $fk_exercise_target_id
        fk_user_subscription_level_id: $fk_user_subscription_level_id
        image_url: $image_url
        plan_name: $plan_name
        routine_days_in_a_week: $routine_days_in_a_week
      }
    ) {
      description
      description_mm
      created_at
      id
    }
  }
`;

//pending status
export const PENDING = gql`
  mutation MyMutation($id: uuid!, $pending: Boolean!) {
    update_plans_by_pk(pk_columns: { id: $id }, _set: { pending: $pending }) {
      id
      pending
    }
  }
`;

export const SUB_TYPE = gql`
  query MyQuery($exeId: uuid!) {
    exercise_routine(where: { id: { _eq: $exeId } }) {
      routine_included_sub_types
      id
    }
  }
`;

// for sub_type dropdown list

//get experience level
export const EXE_LEVEL = gql`
  query MyQuery {
    experience_level {
      id
      name
    }
  }
`;

//push to top
export const TOP = gql`
  mutation MyMutation($id: uuid!, $updated_at: timestamptz!) {
    update_video_list_by_pk(
      pk_columns: { id: $id }
      _set: { updated_at: $updated_at }
    ) {
      id
      updated_at
    }
  }
`;
