import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { Box } from "@mui/system";
import {
  Button,
  Typography,
  Card,
  CardContent,
  TextField,
  CircularProgress,
  Alert,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
} from "@mui/material";

import {
  ADD_SUB_PACKAGE_VARIANT,
  ALL_SUB_PACKAGE_VARIANT,
  EDIT_SUB_PACKAGE_VARIANT,
  SUB_PACKAGE_VARIANT_BY_PK,
} from "../../gql/subscriptionpackageVariant";

const UpdateSubPackageVariant = ({
  handleClose,
  packageId,
  packageVariant,
}) => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [showAlert, setShowAlert] = useState({ message: "", isError: false });

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (packageVariant) {
      setValues({
        id: packageVariant?.id,
        title_en: packageVariant?.title_en,
        title_mm: packageVariant?.title_mm,
        price: parseInt(packageVariant?.price),
        original_price: parseInt(packageVariant?.original_price),
        description_en: packageVariant?.description_en,
        description_mm: packageVariant?.description_mm,
        discount_percentage: parseInt(packageVariant?.discount_percentage),
        durationInDays: parseInt(packageVariant?.durationInDays),
        subscription_package_id: packageVariant?.subscription_package_id,
        disable_promotion: packageVariant?.disable_promotion,
      });
    }
  }, [packageVariant]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const [updatePackageVariant] = useMutation(EDIT_SUB_PACKAGE_VARIANT, {
    onError: (error) => {
      console.log("error : ", error);
      alert("Sub Package Variant Upload Error");
      setLoading(false);
    },
    onCompleted: (result) => {
      setValues({});
      setErrors({});

      setLoading(false);
      // videoAlert("New Video have been created.");
      alert("Sub Package Variant has been Updated");
      handleClose();
    },
    refetchQueries: [SUB_PACKAGE_VARIANT_BY_PK],
  });

  const handleUpdate = async () => {
    setLoading(true);
    setErrors({});
    let isErrorExit = false;
    let errorObject = {};

    // if (!values.description) {
    //   errorObject.description = "Description is required";
    //   isErrorExit = true;
    // }
    // if (!values.description_mm) {
    //   errorObject.description_mm = "Description_mm is required";
    //   isErrorExit = true;
    // }
    // if (!values.video_package_name) {
    //   errorObject.video_package_name = "Video Title field is required.";
    //   isErrorExit = true;
    // }
    // if (!muscleType) {
    //   errorObject.muscleType = "Muscle Type field is required.";
    //   isErrorExit = true;
    // }
    // if (!values.fk_exercise_type_name) {
    //   errorObject.fk_exercise_type_name = "Exercise Type field is required.";
    //   isErrorExit = true;
    // }
    // if (!values.fk_experience_level_id) {
    //   errorObject.fk_experience_level_id = "Experience field is required.";
    //   isErrorExit = true;
    // }
    // if (!values.fk_user_subscription_level_id) {
    //   errorObject.fk_user_subscription_level_id =
    //     "Subscription Level field is required.";
    //   isErrorExit = true;
    // }
    // if (!values.duration_in_minute) {
    //   errorObject.duration_in_minute = "duration_in_minute field is required.";
    //   isErrorExit = true;
    // }

    // // if (!values.package_type) {
    // //   errorObject.package_type = "package_type field is required.";
    // //   isErrorExit = true;
    // // }

    // if (!values.promotion) {
    //   errorObject.promotion = "promotion field is required.";
    //   isErrorExit = true;
    // }

    if (isErrorExit) {
      setErrors({ ...errorObject });
      setLoading(false);
      return;
    }

    try {
      await updatePackageVariant({
        variables: {
          id: values?.id,
          editSubPackageVariant: {
            title_en: values.title_en,
            title_mm: values.title_mm,
            price: parseInt(values.price),
            original_price: parseInt(values.original_price),
            description_en: values.description_en,
            description_mm: values.description_mm,
            discount_percentage: parseInt(values.discount_percentage),
            durationInDays: parseInt(values.durationInDays),
            subscription_package_id: values?.subscription_package_id,
            disable_promotion: values?.disable_promotion,
          },
        },
      });
    } catch (error) {
      console.log("error : ", error);
    }
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          color: "black",
          // width: "94.5vw",
          bgcolor: "#cecece",
          borderTopRightRadius: 10,
          borderTopLeftRadius: 10,
          py: 2,
        }}
      >
        <Typography variant="h5" component="h2" color="black" sx={{ mx: 4 }}>
          Update Subscription Pakcage Variant
        </Typography>
        <Button
          onClick={handleClose}
          variant="contained"
          color="error"
          sx={{ mx: 4 }}
        >
          Close
        </Button>
      </Box>
      <Card
        sx={{
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }}
      >
        <CardContent>
          {/* list items */}
          <div
            className="grid--2--cols grid-item"
            style={{
              columnGap: "5rem",
              rowGap: "2rem",
              paddingLeft: "2rem",
              paddingRight: "2rem",
            }}
          >
            {/* title en */}
            <TextField
              id="title_en"
              label="Title En"
              value={values.title_en}
              onChange={handleChange("title_en")}
              error={errors.title_en ? true : false}
              helperText={errors.title_en}
            />
            {/* title mm*/}
            <TextField
              id="title_mm"
              label="Title MM"
              value={values.title_mm}
              onChange={handleChange("title_mm")}
              error={errors.title_mm ? true : false}
              helperText={errors.title_mm}
            />
            {/*description */}
            <TextField
              id="description_en"
              label="Description En"
              value={values.description_en}
              onChange={handleChange("description_en")}
              error={errors.description_en ? true : false}
              helperText={errors.description_en}
            />
            {/*description mm */}
            <TextField
              id="description_mm"
              label="Description MM"
              value={values.description_mm}
              onChange={handleChange("description_mm")}
              error={errors.description_mm ? true : false}
              helperText={errors.description_mm}
            />
            {/* duration in days */}
            <TextField
              type="number"
              id="durationInDays"
              label="Duration InDays"
              value={values.durationInDays}
              onChange={handleChange("durationInDays")}
              error={errors.durationInDays ? true : false}
              helperText={errors.durationInDays}
            />
            {/* price */}
            <TextField
              type="number"
              id="price"
              label="Price (Actual Sale Price)"
              value={values.price}
              onChange={handleChange("price")}
              error={errors.price ? true : false}
              helperText={errors.price}
            />
            {/* original price */}
            <TextField
              type="number"
              id="price"
              label="Original Price (Before Discount)"
              value={values.original_price}
              onChange={handleChange("original_price")}
              error={errors.original_price ? true : false}
              helperText={errors.original_price}
            />
            {/* discount percentage*/}
            <TextField
              type="number"
              id="discount_percentage"
              label="Discount Percentage"
              value={values?.discount_percentage}
              onChange={handleChange("discount_percentage")}
              error={errors.discount_percentage ? true : false}
              helperText={errors.discount_percentage}
            />
            {/* promotion */}
            <FormControl>
              <InputLabel id="promotion">Promotion Disable</InputLabel>
              <Select
                labelId="promotion"
                value={values.disable_promotion}
                label="Promotion"
                defaultValue=""
                onChange={handleChange("disable_promotion")}
                // error={errors.disable_promotion ? true : false}
              >
                <MenuItem value="" disabled>
                  Value
                </MenuItem>
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
              {errors.disable_promotion && (
                <FormHelperText error>
                  {errors.disable_promotion}
                </FormHelperText>
              )}
            </FormControl>
            {/* description */}
            {/* <Box>
              <InputLabel style={{ marginBottom: 10, fontWeight: "bold" }}>
                Description
              </InputLabel>
              <RichTextEditor
                className="description-text"
                onChange={onChange}
                value={textValue}
                toolbarConfig={toolbarConfig}
              />
              {errors.description_en && (
                <FormHelperText error> {errors.description_en}</FormHelperText>
              )}
            </Box> */}
            {/* description MM*/}
            {/* <Box>
              <InputLabel style={{ marginBottom: 10, fontWeight: "bold" }}>
                Description MM
              </InputLabel>
              <RichTextEditor
                className="description-text"
                //onChange={handleChange("description")}
                onChange={onChangeMM}
                value={textValueMM}
                toolbarConfig={toolbarConfig}
              />
              {errors.description_mm && (
                <FormHelperText error> {errors.description_mm}</FormHelperText>
              )}
            </Box> */}
          </div>
        </CardContent>

        <Box className="btn_end">
          <Button
            variant="contained"
            className="progress"
            size="large"
            sx={{ height: 50, width: 100 }}
            {...(loading && { loading: true })}
            onClick={handleUpdate}
            //style={{ position: "absolute", top: "10px", left: "2px" }}
          >
            {loading ? (
              <CircularProgress
                color="warning"
                value={progress}
                text={`${progress}%`}
              />
            ) : (
              "Update"
            )}
          </Button>
        </Box>
      </Card>

      {showAlert.message && !showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="success"
        >
          {showAlert.message}
        </Alert>
      )}
      {showAlert.message && showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="error"
        >
          {showAlert.message}
        </Alert>
      )}
    </div>
  );
};
export default UpdateSubPackageVariant;
