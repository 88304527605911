import { useLazyQuery, useQuery } from "@apollo/client";
import { useMutation } from "@apollo/client";

import RichTextEditor from "react-rte";
import {
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormHelperText,
  CardMedia,
  Alert,
  CircularProgress,
} from "@mui/material";
import { Box } from "@mui/system";

import { useState, useEffect } from "react";
import { PLAN_PK } from "../../gql/plan";

import { DELETE_IMAGE, GET_IMAGE_UPLOAD_URL } from "../../gql/misc";
import imageService from "../../services/image";
import { useRef } from "react";
import {
  ALL_ROUTINES,
  ALL_VIDOE,
  EXE_TARGET,
  EXP_LEVEL,
  SUBSCRIPTION_TYPE,
} from "../../gql/mixed";
import { useNavigate, useParams } from "react-router-dom";
import { UPDATE_ROUTINE } from "../../gql/routines";
import { UPDATE_EXE_ROUTINE } from "../../gql/exeRoutine";

const fileTypes = [
  "image/apng",
  "image/bmp",
  "image/gif",
  "image/jpeg",
  "image/pjpeg",
  "image/png",
  "image/svg+xml",
  "image/tiff",
  "image/webp",
  "image/x-icon",
];
const imageType = "image/*";
const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: [
    "INLINE_STYLE_BUTTONS",
    "BLOCK_TYPE_BUTTONS",
    "LINK_BUTTONS",
    "BLOCK_TYPE_DROPDOWN",
    "HISTORY_BUTTONS",
  ],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "custom-css-class" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
};
const UpdateExeRoutine = ({ handleClose, exeRoutine }) => {
  const navigate = useNavigate();
  const [values, setValues] = useState({});
  const { id } = useParams();
  const [errors, setErrors] = useState({});

  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState({ message: "", isError: false });
  const { data: videos } = useQuery(ALL_VIDOE);
  const fileInputRef = useRef(null);

  //assign data from db

  useEffect(() => {
    if (exeRoutine) {
      const exeRoutines = exeRoutine;
      setValues(exeRoutines);
    }
  }, [exeRoutine]);

  //for input box
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    delete errors[prop];
    setErrors(errors);
  };

  //for create routine
  const [UpdateRoutine] = useMutation(UPDATE_EXE_ROUTINE, {
    onError: (error) => {
      console.log("error : ", error);
      setLoading(false);
    },
    onCompleted: (result) => {
      setValues({});
      console.log("result ", result);
      setErrors({});

      // fileInputRef.current.value = "";
      setLoading(false);
      alert("Exercise Routine has been updated");
      handleClose();
    },
    // refetchQueries: [],
  });
  console.log("values ", values);
  const handleUpdate = async () => {
    setLoading(true);
    setErrors({});
    let isErrorExit = false;
    let errorObject = {};
    const existsInVideoList = videos?.video_list?.some(
      (item) => item.video_id === values?.fk_video_id
    );

    if (!values.fk_video_id) {
      if (existsInVideoList)
        errorObject.fk_video_id =
          "Video list field is required or video id is incorrect";
      isErrorExit = true;
    }

    // if (!values.plan_name) {
    //   errorObject.plan_name = "Plan name is required";
    //   isErrorExit = true;
    // }
    // if (!values.image_url) {
    //   errorObject.image_url = "Image field is required.";
    //   isErrorExit = true;
    // }
    // if (!values.fk_user_subscription_level_id) {
    //   errorObject.fk_user_subscription_level_id =
    //     "Subscription level is required.";
    //   isErrorExit = true;
    // }
    // if (!values.fk_exercise_target_id) {
    //   errorObject.fk_exercise_target_id = "Exercise Target is required.";
    //   isErrorExit = true;
    // }
    // if (!values.fk_experience_level_id) {
    //   errorObject.fk_experience_level_id = "Experience Level is required.";
    //   isErrorExit = true;
    // }

    // if (!values.description) {
    //   errorObject.description = "Description is required";
    //   isErrorExit = true;
    // }
    // if (!values.description_mm) {
    //   errorObject.description_mm = "Description MM is required";
    //   isErrorExit = true;
    // }

    // if (!values.routine_days_in_a_week) {
    //   errorObject.routine_days_in_a_week = "routine days is required";
    //   isErrorExit = true;
    // }

    if (isErrorExit) {
      setErrors(errorObject);
      console.log("error object", errorObject);
      setLoading(false);
      return;
    }
    try {
      await UpdateRoutine({
        variables: {
          ...values,
          fk_video_id: values?.fk_video_id,
          exercise_routine_name: values?.exercise_routine_name,
          fk_routine_id: values?.fk_routine_id,
        },
      });
    } catch (error) {
      console.log("error : ", error);
    }
  };

  //to clear data after clicking close btn
  const handleClosClearData = () => {
    setValues({});
    setErrors({});
    handleClose();
  };
  // if (!plans) {
  //   return "no plan data";
  // }

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          color: "black",
          bgcolor: "#cecece",
          borderTopRightRadius: 10,
          borderTopLeftRadius: 10,
          py: 2,
        }}
      >
        <Typography variant="h5" component="h2" color="black" sx={{ mx: 4 }}>
          Update Exercise Routine
        </Typography>
        <Button
          onClick={() => navigate(-1)}
          variant="contained"
          color="error"
          sx={{ mx: 4 }}
        >
          Close
        </Button>
      </Box>

      <Card sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
        <CardContent>
          <div className="grid-item grid--2--cols">
            {/* exercise_routine_name  */}
            <TextField
              id="exercise_routine_name"
              label="Exercise Routine Name"
              value={
                values.exercise_routine_name ? values.exercise_routine_name : ""
              }
              onChange={handleChange("exercise_routine_name")}
              error={errors.exercise_routine_name ? true : false}
              helperText={errors.exercise_routine_name}
            />
            {/* routine_name */}
            {/* <FormControl>
            <InputLabel id="main_type">Routine Name</InputLabel>
            <Select
              labelId="routine_name"
              value={values.fk_routine_id}
              label="Routine Name"
              defaultValue=""
              onChange={handleChange("fk_routine_id")}
              error={errors.fk_routine_id ? true : false}
            >
              <MenuItem value="" disabled>
                Value
              </MenuItem>
              {console.log("routine id ", routines)}
              <MenuItem value={routines?.routines_by_pk?.id}>
                {routines?.routines_by_pk?.routine_name}
              </MenuItem>
            </Select>
            {errors.fk_routine_id && (
              <FormHelperText error>{errors.fk_routine_id}</FormHelperText>
            )}
          </FormControl> */}

            <TextField
              id="routine_name"
              label="Routine Name"
              value={values?.routine?.routine_name}
            />

            <TextField
              id="video_id"
              label="Video ID"
              value={values?.fk_video_id}
              onChange={handleChange("fk_video_id")}
              error={errors.fk_video_id ? true : false}
              helperText={errors.fk_video_id}
            />
          </div>
        </CardContent>
        <Box className="btn_end">
          <Button
            variant="contained"
            //color="warning"
            size="large"
            sx={{ height: 50, width: 100 }}
            disabled={loading}
            onClick={handleUpdate}
          >
            {loading ? <CircularProgress color="warning" /> : "Update"}
          </Button>
        </Box>
      </Card>
      {showAlert.message && !showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="success"
        >
          {showAlert.message}
        </Alert>
      )}
      {showAlert.message && showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="error"
        >
          {showAlert.message}
        </Alert>
      )}
    </div>
  );
};

export default UpdateExeRoutine;
