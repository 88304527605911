import React, { useEffect, useRef, useState } from "react";
import imageService from "../../services/image";
import { useMutation } from "@apollo/client";
import { GET_IMAGE_UPLOAD_URL, DELETE_IMAGE } from "../../gql/misc";
import { BLOG, UPDATE_BLOG } from "../../gql/blog";
import RichTextEditor from "react-rte";

import {
  Box,
  FormControl,
  TextField,
  Typography,
  CardMedia,
  InputLabel,
  FormHelperText,
  Button,
  CircularProgress,
  Card,
  CardContent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import imageCompression from "browser-image-compression";
const fileTypes = [
  "image/apng",
  "image/bmp",
  "image/gif",
  "image/jpeg",
  "image/pjpeg",
  "image/png",
  "image/svg+xml",
  "image/tiff",
  "image/webp",
  "image/x-icon",
];

const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: [
    "INLINE_STYLE_BUTTONS",
    "BLOCK_TYPE_BUTTONS",
    "LINK_BUTTONS",
    "BLOCK_TYPE_DROPDOWN",
    "HISTORY_BUTTONS",
  ],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "custom-css-class" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
};

const UpdateBlog = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [imagePreview, setImagePreview] = useState(null);
  const [isImageChange, setIsImageChange] = useState(false);
  const [oldImageName, setOldImageName] = useState(null);
  const [image, setImage] = useState();
  const [textValue, setTextValue] = useState(RichTextEditor.createEmptyValue());
  const [textValueMM, setTextValueMM] = useState(
    RichTextEditor.createEmptyValue()
  );
  const [getImageUrl] = useMutation(GET_IMAGE_UPLOAD_URL);
  const fileInputRef = useRef(null);
  useEffect(() => {
    let blog = props.blog;
    setValues({
      title: blog.title ? blog.title : null,
      body: blog.body,
      title_image_url: blog.title_image_url,
      thumbnail_image_url: blog.thumbnail_image_url,
      body_mm: blog.body_mm,
    });
    setTextValue(RichTextEditor.createValueFromString(blog.body, "html"));
    setTextValueMM(RichTextEditor.createValueFromString(blog.body_mm, "html"));
    setImagePreview(blog.title_image_url);

    let image_url = blog.title_image_url;
    setOldImageName(
      image_url.substring(image_url.lastIndexOf("/") + 1, image_url.lenght)
    );
  }, [props.blog]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const [updateBlog] = useMutation(UPDATE_BLOG, {
    onError: (error) => {
      console.log("error : ", error);
    },
    onCompleted: () => {
      setValues({ title: "", body: "", title_image_url: "" });
      setErrors({ title: "", body: "", title_image_url: "" });
      setTextValue(RichTextEditor.createEmptyValue());

      setImagePreview("");
      setLoading(false);
      // props.blogAlert("Blog have been updated.", false);
      // props.handleClose();
      alert("Blog has been Updated");
    },
    refetchQueries: [BLOG],
  });

  const [deleteImage] = useMutation(DELETE_IMAGE, {
    onError: (error) => {
      console.log("error : ", error);
      setLoading(false);
    },
  });
  const [deleteOuterImage] = useMutation(DELETE_IMAGE, {
    onError: (error) => {
      console.log("error : ", error);
      setLoading(false);
    },
  });

  const imageChange = async (e) => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0];
      if (!fileTypes.includes(img.type)) {
        setErrors({
          ...errors,
          title_image_url: "Please select image. (PNG, JPG, JPEG, GIF, ...)",
        });
        return;
      }

      setIsImageChange(true);
      setImagePreview(URL.createObjectURL(img));
      setImage(img);
    }
  };
  const handleUpdate = async () => {
    setLoading(true);
    setErrors({ title: "", body: "", title_image_url: "" });
    let isErrorExit = false;
    let errorObject = {};
    if (!values.title) {
      errorObject.name = "Title field is required.";
      isErrorExit = true;
    }
    if (!values.body) {
      errorObject.body = "Paragraph field is required.";
      isErrorExit = true;
    }
    if (isErrorExit) {
      setErrors({ ...errorObject });
      setLoading(false);
      return;
    }
    try {
      if (isImageChange) {
        const options = {
          initialQuality: 0.5,
          maxWidthOrHeight: 720,
          useWebWorker: true,
        };
        const compressedTitleImage = await imageCompression(image, options);

        const titleImageFile = await getImageUrl({
          variables: { contentType: "image" },
        });

        await imageService.uploadImage(
          titleImageFile.data.getImageUploadUrl.imageUploadUrl,
          compressedTitleImage
        );

        //---------------------
        const thumbOptions = {
          initialQuality: 0.5,
          maxWidthOrHeight: 360,
          useWebWorker: true,
        };
        const compressedThumbImage = await imageCompression(
          image,
          thumbOptions
        );

        const thumbImageFile = await getImageUrl({
          variables: { contentType: "image" },
        });

        await imageService.uploadImage(
          thumbImageFile.data.getImageUploadUrl.imageUploadUrl,
          compressedThumbImage
        );

        await updateBlog({
          variables: {
            ...values,
            id: props.blog.id,
            title_image_url: `https://axra.sgp1.digitaloceanspaces.com/PowerPlay/${titleImageFile.data.getImageUploadUrl.imageName}`,
            thumbnail_image_url: `https://axra.sgp1.digitaloceanspaces.com/PowerPlay/${thumbImageFile.data.getImageUploadUrl.imageName}`,
          },
        });
        await deleteImage({ variables: { image_name: oldImageName } });
      } else {
        await updateBlog({
          variables: {
            ...values,
            id: props.blog.id,
          },
        });
      }

      navigate("/blog");
    } catch (error) {
      console.log("error : ", error);
    }
  };

  const onChange = (value) => {
    setTextValue(value);
    setValues({ ...values, body: value.toString("html") });
  };
  const onChangeMM = (value) => {
    setTextValueMM(value);
    setValues({ ...values, body_mm: value.toString("html") });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          color: "black",
          bgcolor: "#cecece",
          borderTopRightRadius: 10,
          borderTopLeftRadius: 10,
          py: 2,
        }}
      >
        <Typography variant="h5" component="h2" color="black" sx={{ mx: 4 }}>
          Update Blog
        </Typography>
        <Button
          onClick={() => navigate("/blog")}
          variant="contained"
          color="error"
          sx={{ mx: 4 }}
        >
          Close
        </Button>
      </Box>

      <Card sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
        <CardContent>
          <Box sx={{ display: "flex", mb: "4rem" }}>
            {/* image */}

            <Box className="grid-img">
              <CardMedia
                sx={{
                  flex: 1,
                  bgcolor: "#cecece",
                  objectFit: "contain",
                  mt: 4,
                  boxShadow: 5,
                  borderRadius: 2,
                }}
                component="img"
                height="300"
                image={imagePreview}
                alt="exercise image"
                className="grid_img"
              />
            </Box>

            {/* <Box className="grid-img">
              <CardMedia
                sx={{
                  flex: 1,
                  bgcolor: "#cecece",
                  objectFit: "contain",
                  mt: 4,
                  boxShadow: 5,
                  borderRadius: 2,
                }}
                component="img"
                height="300"
                image={outerImagePreview}
                alt="exercise image"
              />
            </Box> */}
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "50% 50%",
              gap: "2rem",
            }}
          >
            {/* title_image_url */}
            <TextField
              id="title_image_url"
              label="Inner Image"
              type="file"
              accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml"
              InputLabelProps={{ shrink: true }}
              //value={values.thumbnail_image_url}
              onChange={imageChange}
              error={errors.title_image_url ? true : false}
              helperText={errors.title_image_url}
              inputRef={fileInputRef}
            />
            {/* thumbnail_image_url */}
            {/* <TextField
              id="thumbnail_image_url"
              label="Outer Image"
              type="file"
              accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml"
              InputLabelProps={{ shrink: true }}
              //value={values.thumbnail_image_url}
              onChange={outerImageChange}
              error={errors.thumbnail_image_url ? true : false}
              helperText={errors.thumbnail_image_url}
              inputRef={fileInputRef}
            /> */}
            {/* Title */}
            <TextField
              id="title"
              label="Title"
              value={values.title}
              onChange={handleChange("title")}
              error={errors.title ? true : false}
              helperText={errors.title}
            />

            {/* Body */}
            <Box>
              <InputLabel style={{ marginBottom: 10, fontWeight: "bold" }}>
                Description
              </InputLabel>
              <RichTextEditor
                className="description-text"
                y
                onChange={onChange}
                value={textValue ? textValue : ""}
                toolbarConfig={toolbarConfig}
              />
              {errors.body && (
                <FormHelperText error> {errors.body}</FormHelperText>
              )}
            </Box>
            {/* description_mm */}
            <Box>
              <InputLabel>Description MM</InputLabel>
              <RichTextEditor
                className="description-text"
                value={textValueMM}
                onChange={onChangeMM}
                toolbarConfig={toolbarConfig}
              />
            </Box>
          </Box>
        </CardContent>
        <Box className="btn_end">
          <Button
            variant="contained"
            //color="warning"
            size="large"
            sx={{ height: 50, width: 100 }}
            disabled={loading}
            onClick={handleUpdate}
          >
            {/* {loading ? (
            <CircularProgress
              color="warning"
              value={progress}
              //text={`${progress}%`}
            />
          ) : (
            "Create"
          )} */}
            {loading ? <CircularProgress color="warning" /> : "Update"}
          </Button>
        </Box>
      </Card>
    </>
  );
};

export default UpdateBlog;
