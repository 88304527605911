import React, { useRef, useState } from "react";
import imageService from "../../services/image";
import { useMutation } from "@apollo/client";
import { GET_IMAGE_UPLOAD_URL } from "../../gql/misc";
import RichTextEditor from "react-rte";
import imageCompression from "browser-image-compression";
import {
  Box,
  FormControl,
  TextField,
  Typography,
  CardMedia,
  Alert,
  InputLabel,
  FormHelperText,
  Button,
  CircularProgress,
  Card,
  CardContent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import { CREATE_BLOG } from "../../gql/blog";
import { useNavigate } from "react-router-dom";

const fileTypes = [
  "image/apng",
  "image/bmp",
  "image/gif",
  "image/jpeg",
  "image/pjpeg",
  "image/png",
  "image/svg+xml",
  "image/tiff",
  "image/webp",
  "image/x-icon",
];

const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: [
    "INLINE_STYLE_BUTTONS",
    "BLOCK_TYPE_BUTTONS",
    "LINK_BUTTONS",
    "BLOCK_TYPE_DROPDOWN",
    "HISTORY_BUTTONS",
  ],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "custom-css-class" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
};
const CreateBlog = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState({ message: "", isError: false });
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [imagePreview, setImagePreview] = useState(null);

  const [image, setImage] = useState();
  const [textValue, setTextValue] = useState(RichTextEditor.createEmptyValue());
  const [textValueMM, setTextValueMM] = useState(
    RichTextEditor.createEmptyValue()
  );
  const fileInputRef = useRef(null);
  const [getImageUrl] = useMutation(GET_IMAGE_UPLOAD_URL);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const [createBlog] = useMutation(CREATE_BLOG, {
    onError: (error) => {
      console.log("error : ", error);
      alert("Blog Upload Error", error);
      setShowAlert({ message: "Error on server", isError: true });
      setTimeout(() => {
        setShowAlert({ message: "", isError: false });
      }, 1000);
      setLoading(false);
    },
    onCompleted: (result) => {
      setValues({ title: "", body: "", title_image_url: "" });
      setErrors({ title: "", body: "", title_image_url: "" });
      setTextValue(RichTextEditor.createEmptyValue());

      setImagePreview("");
      setLoading(false);
      setShowAlert({ message: "Blog have been created.", isError: false });
      setTimeout(() => {
        setShowAlert({ message: "", isError: false });
      }, 1000);
    },
  });

  const imageChange = async (e) => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0];
      if (!fileTypes.includes(img.type)) {
        setErrors({
          ...errors,
          title_image_url: "Please select image. (PNG, JPG, JPEG, GIF, ...)",
        });
        return;
      }
      setImagePreview(URL.createObjectURL(img));
      setImage(img);
    }
  };
  const handleCreate = async () => {
    setLoading(true);
    setErrors({ title: "", paragraph: "", image_url: "" });
    let isErrorExit = false;
    let errorObject = {};
    if (!values.title) {
      errorObject.title = "Title field is required.";
      isErrorExit = true;
    }
    if (!values.body) {
      errorObject.body = "Body field is required.";
      isErrorExit = true;
    }
    if (!values.body_mm) {
      errorObject.body_mm = "Body MM field is required.";
      isErrorExit = true;
    }
    if (!image) {
      errorObject.image = "Image field is required.";
      isErrorExit = true;
    }

    if (isErrorExit) {
      setErrors({ ...errorObject });
      setLoading(false);
      return;
    }
    try {
      const options = {
        initialQuality: 0.5,
        maxWidthOrHeight: 720,
        useWebWorker: true,
      };
      const compressedTitleImage = await imageCompression(image, options);

      const titleImageFile = await getImageUrl({
        variables: { contentType: "image" },
      });

      await imageService.uploadImage(
        titleImageFile.data.getImageUploadUrl.imageUploadUrl,
        compressedTitleImage
      );

      // -------------------------
      const thumbOptions = {
        initialQuality: 0.5,
        maxWidthOrHeight: 360,
        useWebWorker: true,
      };
      const compressedThumbImage = await imageCompression(image, thumbOptions);

      const thumbImageFile = await getImageUrl({
        variables: { contentType: "image" },
      });

      await imageService.uploadImage(
        thumbImageFile.data.getImageUploadUrl.imageUploadUrl,
        compressedThumbImage
      );

      await createBlog({
        variables: {
          ...values,
          thumbnail_image_url: `https://axra.sgp1.digitaloceanspaces.com/PowerPlay/${thumbImageFile.data.getImageUploadUrl.imageName}`,
          title_image_url: `https://axra.sgp1.digitaloceanspaces.com/PowerPlay/${titleImageFile.data.getImageUploadUrl.imageName}`,
        },
      });
      navigate("/blog");
    } catch (error) {
      console.log("error : ", error);
    }
  };

  const onChange = (value) => {
    setTextValue(value);
    setValues({ ...values, body: value.toString("html") });
  };
  const onChangeMM = (value) => {
    setTextValueMM(value);
    setValues({ ...values, body_mm: value.toString("html") });
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          color: "black",
          bgcolor: "#cecece",
          borderTopRightRadius: 10,
          borderTopLeftRadius: 10,
          py: 1,
        }}
      >
        <Typography variant="h5" component="h2" color="black" sx={{ mx: 4 }}>
          Create Blog
        </Typography>
        <Button
          onClick={() => navigate("/blog")}
          variant="contained"
          color="error"
          sx={{ mx: 4 }}
        >
          Close
        </Button>
      </Box>

      <Card sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
        <CardContent>
          {/* image */}
          <Box sx={{ display: "flex", mb: "4rem" }}>
            <Box className="grid-img">
              <CardMedia
                sx={{
                  flex: 1,
                  bgcolor: "#cecece",
                  objectFit: "contain",
                  mt: 4,
                  boxShadow: 5,
                  borderRadius: 2,
                }}
                component="img"
                height="300"
                image={imagePreview}
                alt="blog image"
                className="grid_img"
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "50% 50%",
              gap: "1rem",
            }}
          >
            {/* title_image_url */}
            <TextField
              id="title_image_url"
              label="Inner Image "
              type="file"
              accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml"
              InputLabelProps={{ shrink: true }}
              //value={values.thumbnail_image_url}
              onChange={imageChange}
              error={errors.image ? true : false}
              helperText={errors.image}
              inputRef={fileInputRef}
            />

            {/* Title */}
            <TextField
              id="title"
              label="Title"
              value={values.title ? values.title : ""}
              onChange={handleChange("title")}
              error={errors.title ? true : false}
              helperText={errors.title}
            />

            {/* Body */}
            <Box sx={{ width: "100%" }}>
              <InputLabel style={{ marginBottom: 10, fontWeight: "bold" }}>
                Description
              </InputLabel>
              <RichTextEditor
                className="description-text"
                onChange={onChange}
                value={textValue ? textValue : ""}
                toolbarConfig={toolbarConfig}
              />
              {errors.body && (
                <FormHelperText error> {errors.body}</FormHelperText>
              )}
            </Box>
            {/* description_mm */}
            <Box>
              <InputLabel>Description MM</InputLabel>
              <RichTextEditor
                className="description-text"
                onChange={onChangeMM}
                value={textValueMM}
                toolbarConfig={toolbarConfig}
              />
              {errors.body_mm && (
                <FormHelperText error> {errors.body_mm}</FormHelperText>
              )}
            </Box>
          </Box>
        </CardContent>
        <Box className="btn_end">
          <Button
            variant="contained"
            //color="warning"
            size="large"
            sx={{ height: 50, width: 100 }}
            disabled={loading}
            onClick={handleCreate}
          >
            {loading ? <CircularProgress color="warning" /> : "Create"}
          </Button>
        </Box>
      </Card>
      {showAlert.message && !showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="success"
        >
          {showAlert.message}
        </Alert>
      )}
      {showAlert.message && showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="error"
        >
          {showAlert.message}
        </Alert>
      )}
    </div>
  );
};

export default CreateBlog;
