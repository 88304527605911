import React, { useRef, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";

import {
  Box,
  FormControl,
  TextField,
  Typography,
  Alert,
  InputLabel,
  FormHelperText,
  Button,
  CircularProgress,
  Select,
  MenuItem,
  Card,
  CardContent,
} from "@mui/material";

import { useNavigate, useParams } from "react-router-dom";

import { ALL_ROUTINES, ALL_VIDOE } from "../../gql/mixed";
import { CREATE_EXE_ROUTINE } from "../../gql/exeRoutine";

const CreateExeRoutine = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState({ message: "", isError: false });
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});

  const { data: routines } = useQuery(ALL_ROUTINES, { variables: { id: id } });
  const { data: videos } = useQuery(ALL_VIDOE);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const [CreateRoutine] = useMutation(CREATE_EXE_ROUTINE, {
    onError: (error) => {
      console.log("error : ", error);
      alert("Exercise Routine Upload Error", error);
      setShowAlert({ message: "Error on server", isError: true });
      setTimeout(() => {
        setShowAlert({ message: "", isError: false });
      }, 1000);
      setLoading(false);
    },
    onCompleted: () => {
      setValues({});
      setErrors({});
      alert("Exe Routine has been created");

      setLoading(false);
      setShowAlert({ message: "Blog have been created.", isError: false });
      setTimeout(() => {
        setShowAlert({ message: "", isError: false });
      }, 1000);
    },
  });

  const handleCreate = async () => {
    setLoading(true);
    setErrors({});
    let isErrorExit = false;
    let errorObject = {};
    if (!values.exercise_routine_name) {
      errorObject.exercise_routine_name = "Exe Routine Name field is required.";
      isErrorExit = true;
    }
    // if (!values.fk_routine_id) {
    //   errorObject.fk_routine_id = "Routine Name field is required.";
    //   isErrorExit = true;
    // }

    const existsInVideoList = videos?.video_list?.some(
      (item) => item.video_id === values?.fk_video_id
    );

    if (!values.fk_video_id) {
      if (existsInVideoList)
        errorObject.fk_video_id =
          "Video list field is required or video id is incorrect";
      isErrorExit = true;
    }

    if (isErrorExit) {
      setErrors({ ...errorObject });
      console.log("error object ", errorObject);
      setLoading(false);
      return;
    }
    try {
      CreateRoutine({
        variables: { ...values, fk_routine_id: routines?.routines_by_pk?.id },
      });
      navigate(-1);
    } catch (error) {
      console.log("error : ", error);
    }
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          color: "black",
          bgcolor: "#cecece",
          borderTopRightRadius: 10,
          borderTopLeftRadius: 10,
          py: 2,
        }}
      >
        <Typography variant="h5" component="h2" color="black" sx={{ mx: 4 }}>
          Create Exercise Routine
        </Typography>
        <Button
          onClick={() => navigate(-1)}
          variant="contained"
          color="error"
          sx={{ mx: 4 }}
        >
          Close
        </Button>
      </Box>

      <Card sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
        <CardContent>
          <div className="grid-item grid--2--cols">
            {/* exercise_routine_name  */}
            <TextField
              id="exercise_routine_name"
              label="Exercise Routine Name"
              value={
                values.exercise_routine_name ? values.exercise_routine_name : ""
              }
              onChange={handleChange("exercise_routine_name")}
              error={errors.exercise_routine_name ? true : false}
              helperText={errors.exercise_routine_name}
            />
            {/* routine_name */}
            {/* <FormControl>
              <InputLabel id="main_type">Routine Name</InputLabel>
              <Select
                labelId="routine_name"
                value={values.fk_routine_id}
                label="Routine Name"
                defaultValue=""
                onChange={handleChange("fk_routine_id")}
                error={errors.fk_routine_id ? true : false}
              >
                <MenuItem value="" disabled>
                  Value
                </MenuItem>
                {console.log("routine id ", routines)}
                <MenuItem value={routines?.routines_by_pk?.id}>
                  {routines?.routines_by_pk?.routine_name}
                </MenuItem>
              </Select>
              {errors.fk_routine_id && (
                <FormHelperText error>{errors.fk_routine_id}</FormHelperText>
              )}
            </FormControl> */}

            <TextField
              id="routine_name"
              label="Routine Name"
              value={routines?.routines_by_pk?.routine_name}
            />

            <TextField
              id="video_id"
              label="Video ID"
              value={values.fk_video_id ? values.fk_video_id : ""}
              onChange={handleChange("fk_video_id")}
              error={errors.fk_video_id ? true : false}
              helperText={errors.fk_video_id}
            />
          </div>
        </CardContent>
        <Box className="btn_end">
          <Button
            variant="contained"
            //color="warning"
            size="large"
            sx={{ height: 50, width: 100 }}
            disabled={loading}
            onClick={handleCreate}
          >
            {loading ? <CircularProgress color="warning" /> : "Create"}
          </Button>
        </Box>
      </Card>
      {showAlert.message && !showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="success"
        >
          {showAlert.message}
        </Alert>
      )}
      {showAlert.message && showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="error"
        >
          {showAlert.message}
        </Alert>
      )}
    </div>
  );
};

export default CreateExeRoutine;
