import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import imageService from "../../services/image";
import { GET_IMAGE_UPLOAD_URL } from "../../gql/misc";
import { Box } from "@mui/system";
import RichTextEditor from "react-rte";
import imageCompression from "browser-image-compression";
import {
  ALL_VIDEOS,
  CREATE_VIDEOS,
  CREATE_VIDEO_MUSCLE_TYPE_LIST,
} from "../../gql/video";

import {
  Button,
  Typography,
  Card,
  CardMedia,
  CardContent,
  TextField,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  FormHelperText,
  CircularProgress,
  Alert,
  ListItemText,
  OutlinedInput,
  Checkbox,
} from "@mui/material";
import { CheckBox } from "@mui/icons-material";
import {
  EXE_TYPE,
  EXP_LEVEL,
  MUSCLE_TYPE,
  SUBSCRIPTION_TYPE,
} from "../../gql/mixed";

const CreateVideo = ({ handleClose }) => {
  const MenuProps = {
    PaperProps: {
      style: {
        width: 250,
        maxHeight: "80vh",
      },
    },
  };

  const fileTypes = ["video/webm", "video/mp4"];
  const thumbnailfileTypes = [
    "image/apng",
    "image/bmp",
    "image/gif",
    "image/jpeg",
    "image/pjpeg",
    "image/png",
    "image/svg+xml",
    "image/tiff",
    "image/webp",
  ];
  const imageType = "image/*";
  const videoType = "video/*";
  const [imagePreview, setImagePreview] = useState();
  const [imageFile, setImageFile] = useState();
  const [imageFileUrl, setImageFileUrl] = useState();
  const [image, setImage] = useState();

  const [videoAFile, setVideoAFile] = useState("");
  const [videoAFileUrl, setVideoAFileUrl] = useState("");
  const [videoAPreview, setVideoAPreview] = useState();

  const [videoBFile, setVideoBFile] = useState("");
  const [videoBFileUrl, setVideoBFileUrl] = useState("");
  const [videoBPreview, setVideoBPreview] = useState();

  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [showAlert, setShowAlert] = useState({ message: "", isError: false });

  const [textValue, setTextValue] = useState(RichTextEditor.createEmptyValue());
  const [textValueMM, setTextValueMM] = useState(
    RichTextEditor.createEmptyValue()
  );

  const [progress, setProgress] = useState(0);

  const [muscleType, setMuscleType] = useState([]);

  const { data: exeTypes } = useQuery(EXE_TYPE);
  const { data: expLevels } = useQuery(EXP_LEVEL);
  const { data: muslceTypess } = useQuery(MUSCLE_TYPE);
  const { data: subscriptionTypes } = useQuery(SUBSCRIPTION_TYPE);
  const [getImageUrl] = useMutation(GET_IMAGE_UPLOAD_URL);
  const handleChangesMuscleType = (event) => {
    const {
      target: { value },
    } = event;

    // setSubType(typeof value === "string" ? value.split(",") : value);
    setMuscleType((prevSubType) => {
      const newValue = typeof value === "string" ? value.split(",") : value;

      return newValue;
    });
  };
  const getSubtypeNameById = (uuid) => {
    const subtype = muslceTypess?.muscle_types.find(
      (subtype) => subtype.id === uuid
    );
    return subtype ? subtype.name : "";
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  // const [getImageUrl] = useMutation(GET_IMAGE_UPLOAD_URL, {
  //   onError: (error) => {
  //     console.log("error : ", error);
  //   },
  //   onCompleted: (result) => {
  //     setImageFileUrl(result.getImageUploadUrl.imageUploadUrl);
  //     setValues({
  //       ...values,
  //       thumbnail_image_url: `https://axra.sgp1.digitaloceanspaces.com/PowerPlay/${result.getImageUploadUrl.imageName}`,
  //     });
  //   },
  // });

  const [getVideoAUrl] = useMutation(GET_IMAGE_UPLOAD_URL, {
    onError: (error) => {
      console.log("error : ", error);
    },
    onCompleted: (result) => {
      setVideoAFileUrl(result.getImageUploadUrl.imageUploadUrl);
      setValues({
        ...values,
        video_url_a: `https://axra.sgp1.digitaloceanspaces.com/PowerPlay/${result.getImageUploadUrl.imageName}`,
      });
    },
  });

  const [getVideoBUrl] = useMutation(GET_IMAGE_UPLOAD_URL, {
    onError: (error) => {
      console.log("error : ", error);
    },
    onCompleted: (result) => {
      setVideoBFileUrl(result.getImageUploadUrl.imageUploadUrl);
      setValues({
        ...values,
        video_url_b: `https://axra.sgp1.digitaloceanspaces.com/PowerPlay/${result.getImageUploadUrl.imageName}`,
      });
    },
  });

  const [createVideoSubType] = useMutation(CREATE_VIDEO_MUSCLE_TYPE_LIST, {
    onError: (err) => {
      console.log("Error ", err);
    },
    onCompleted: (res) => {
      console.log("Result ", res);
    },
  });

  const [createVideo] = useMutation(CREATE_VIDEOS, {
    onError: (error) => {
      console.log("error : ", error);
      alert("Video Upload Error");
      setLoading(false);
      alert("Upload Error");
    },
    onCompleted: (result) => {
      muscleType?.map(async (item) => {
        await createVideoSubType({
          variables: {
            fk_muscle_type_id: item,
            fk_video_list_id: result.insert_video_list_one.id,
          },
        });
      });

      setValues({});
      setErrors({});
      setImageFile("");
      setImagePreview("");
      setLoading(false);
      // videoAlert("New Video have been created.");
      alert("New Video has been Created");
      handleClose();
    },
    refetchQueries: [ALL_VIDEOS],
  });

  // const thumbnailImageChange = async (e) => {
  //   if (e.target.files && e.target.files[0]) {
  //     let thumbImg = e.target.files[0];
  //     if (!thumbnailfileTypes.includes(thumbImg.type)) {
  //       setErrors({
  //         ...errors,
  //         thumbnail_image_url: "Please select images. (JPEG,PNG,...)",
  //       });
  //       return;
  //     }
  //     if (thumbImg.size > 10485760) {
  //       setErrors({
  //         ...errors,
  //         thumbnail_image_url: "Image file size must be smaller than 10MB.",
  //       });
  //       return;
  //     }
  //     setImageFile(thumbImg);
  //     setImagePreview(URL.createObjectURL(thumbImg));
  //     getImageUrl({ variables: { contentType: imageType } });
  //   }
  // };

  const thumbnailImageChange = async (e) => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0];

      if (!thumbnailfileTypes.includes(img.type)) {
        setErrors({
          ...errors,
          thumbnail_image_url:
            "Please select image. (PNG, JPG, JPEG, GIF, ...)",
        });
        return;
      }
      setImagePreview(URL.createObjectURL(img));
      setImage(img);
    }
  };

  const videoChangeA = async (e) => {
    if (e.target.files && e.target.files[0]) {
      let videofile = e.target.files[0];

      if (!fileTypes.includes(videofile.type)) {
        setErrors({
          ...errors,
          video_url_a: "Please select video. (mp4,webm,...)",
        });
        return;
      }
      const video = document.createElement("video");
      video.src = window.URL.createObjectURL(videofile);

      video.onloadedmetadata = () => {
        if (video.videoHeight !== 720 && video.videoWidth !== 1280) {
          setErrors({
            ...errors,
            video_url_a: "Video resolution must be 1280*720 ",
          });
          return;
        }
      };

      setVideoAFile(videofile);
      setVideoAPreview(URL.createObjectURL(videofile));
      getVideoAUrl({ variables: { contentType: videoType } });
    }
  };

  const videoChangeB = async (e) => {
    if (e.target.files && e.target.files[0]) {
      let videofile = e.target.files[0];

      const video = document.createElement("video");
      video.src = window.URL.createObjectURL(videofile);
      video.onloadedmetadata = () => {
        if (video.videoHeight !== 720 && video.videoWidth !== 1280) {
          setErrors({
            ...errors,
            video_url_b: "Video resolution must be 1280*720 ",
          });
        }
        return;
      };

      if (!fileTypes.includes(videofile.type)) {
        setErrors({
          ...errors,
          video_url_b: "Please select video. (mp4,webm,)",
        });
        return;
      }

      setVideoBFile(videofile);
      setVideoBPreview(URL.createObjectURL(videofile));
      getVideoBUrl({ variables: { contentType: videoType } });
    }
  };

  const handleCreate = async () => {
    setLoading(true);
    setErrors({});
    let isErrorExit = false;
    let errorObject = {};

    if (!values.video_url_a && !values.video_url_b) {
      errorObject.video_url_b = "Video A or B field is required.";
      isErrorExit = true;
    }

    // if (!values.thumbnail_image_url) {
    //   errorObject.thumbnail_image_url = "Video Image field is required.";
    //   isErrorExit = true;
    // }
    if (!values.description) {
      errorObject.description = "Description is required";
      isErrorExit = true;
    }
    if (!values.description_mm) {
      errorObject.description_mm = "Description_mm is required";
      isErrorExit = true;
    }
    if (!values.video_package_name) {
      errorObject.video_package_name = "Video Title field is required.";
      isErrorExit = true;
    }
    if (!muscleType) {
      errorObject.muscleType = "Muscle Type field is required.";
      isErrorExit = true;
    }
    if (!values.fk_exercise_type_name) {
      errorObject.fk_exercise_type_name = "Exercise Type field is required.";
      isErrorExit = true;
    }
    if (!values.fk_experience_level_id) {
      errorObject.fk_experience_level_id = "Experience field is required.";
      isErrorExit = true;
    }
    if (!values.fk_user_subscription_level_id) {
      errorObject.fk_user_subscription_level_id =
        "Subscription Level field is required.";
      isErrorExit = true;
    }
    if (!values.duration_in_minute) {
      errorObject.duration_in_minute = "duration_in_minute field is required.";
      isErrorExit = true;
    }

    // if (!values.package_type) {
    //   errorObject.package_type = "package_type field is required.";
    //   isErrorExit = true;
    // }

    if (!values.promotion) {
      errorObject.promotion = "promotion field is required.";
      isErrorExit = true;
    }

    if (isErrorExit) {
      setErrors({ ...errorObject });
      setLoading(false);
      return;
    }

    try {
      const thumbOptions = {
        initialQuality: 0.5,
        maxWidthOrHeight: 500,
        useWebWorker: true,
      };
      const compressedThumbImage = await imageCompression(image, thumbOptions);

      const thumbImageFile = await getImageUrl({
        variables: { contentType: "image" },
      });

      await imageService.uploadImage(
        thumbImageFile.data.getImageUploadUrl.imageUploadUrl,
        compressedThumbImage
      );

      //--------------- low res image----------------
      const options = {
        initialQuality: 0.5,
        maxWidthOrHeight: 100,
        useWebWorker: true,
      };
      const compressedLowResImage = await imageCompression(image, options);

      const lowResImageFile = await getImageUrl({
        variables: { contentType: "image" },
      });

      await imageService.uploadImage(
        lowResImageFile.data.getImageUploadUrl.imageUploadUrl,
        compressedLowResImage
      );

      if (videoAFile && videoBFile) {
        await imageService.uploadImage(videoAFileUrl, videoAFile);
        await imageService.uploadImage(videoBFileUrl, videoBFile);
      } else {
        await imageService.uploadImage(
          videoAFileUrl || videoBFileUrl,
          videoAFile || videoBFile
        );
      }

      await createVideo({
        variables: {
          ...values,
          thumbnail_image_url: `https://axra.sgp1.digitaloceanspaces.com/PowerPlay/${thumbImageFile.data.getImageUploadUrl.imageName}`,
          low_res_image_url: `https://axra.sgp1.digitaloceanspaces.com/PowerPlay/${lowResImageFile.data.getImageUploadUrl.imageName}`,
          video_url_a: values.video_url_a,
          video_url_b: values.video_url_b,
          fk_muscle_type_id: muscleType,
        },
      });
    } catch (error) {
      console.log("error : ", error);
    }
  };

  // for Description
  const onChange = (value) => {
    setTextValue(value);
    setValues({
      ...values,
      description: value.toString("html"),
    });
  };

  // for Description
  const onChangeMM = (value) => {
    setTextValueMM(value);
    setValues({
      ...values,
      description_mm: value.toString("html"),
    });
  };

  const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: [
      "INLINE_STYLE_BUTTONS",
      "BLOCK_TYPE_BUTTONS",
      "LINK_BUTTONS",
      "BLOCK_TYPE_DROPDOWN",
      "HISTORY_BUTTONS",
    ],
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD", className: "custom-css-class" },
      { label: "Italic", style: "ITALIC" },
      { label: "Underline", style: "UNDERLINE" },
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: "Normal", style: "unstyled" },
      { label: "Heading Large", style: "header-one" },
      { label: "Heading Medium", style: "header-two" },
      { label: "Heading Small", style: "header-three" },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" },
    ],
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          color: "black",
          // width: "94.5vw",
          bgcolor: "#cecece",
          borderTopRightRadius: 10,
          borderTopLeftRadius: 10,
          py: 2,
        }}
      >
        <Typography variant="h5" component="h2" color="black" sx={{ mx: 4 }}>
          Create Video
        </Typography>
        <Button
          onClick={handleClose}
          variant="contained"
          color="error"
          sx={{ mx: 4 }}
        >
          Close
        </Button>
      </Box>
      <Card
        sx={{
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }}
      >
        <CardContent>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "5rem",
              marginBottom: "2rem",
            }}
          >
            {/* image */}
            <Box>
              <CardMedia
                component="img"
                height="300"
                image={imagePreview}
                className="grid_img"
                sx={{
                  flex: 1,
                  // bgcolor: "#eee",
                  maxHeight: 300,
                  objectFit: "contain",
                  width: 300,
                  mt: 4,
                  // boxShadow: 3,
                  border: 0.1,
                  borderRadius: 2,
                }}
              />
              <Typography
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "blue",
                  fontSize: "15px",
                  marginTop: "5px",
                }}
              >
                Image Preview
              </Typography>
            </Box>
            {/* video A preview */}
            <Box>
              <CardMedia
                component="iframe"
                height="300"
                image={videoAPreview}
                sx={{ mt: 4, borderRadius: 2, border: 0.1 }}
              />
              <Typography
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "blue",
                  fontSize: "15px",
                  marginTop: "5px",
                }}
              >
                Video A Preview
              </Typography>
            </Box>
            {/* vide B preview */}
            <div>
              <CardMedia
                component="iframe"
                height="300"
                image={videoBPreview}
                sx={{ mt: 4, borderRadius: 2, border: 0.1 }}
              />
              <Typography
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "blue",
                  fontSize: "15px",
                  marginTop: "5px",
                }}
              >
                Video B Preview
              </Typography>
            </div>
          </div>

          {/* list items */}
          <div
            className="grid--2--cols grid-item"
            style={{
              columnGap: "5rem",
              rowGap: "2rem",
              paddingLeft: "2rem",
              paddingRight: "2rem",
            }}
          >
            {/* video url a */}
            <TextField
              id="video_url_a"
              label="Upload Video A"
              type="file"
              InputLabelProps={{ shrink: true }}
              onChange={videoChangeA}
              error={errors.video_url_a ? true : false}
              helperText={errors.video_url_a}
              accept="video/webm,video/mp4"
            />
            {/* video_url_b */}
            <TextField
              id="video_url_b"
              label="Upload Video B"
              type="file"
              InputLabelProps={{ shrink: true }}
              onChange={videoChangeB}
              error={errors.video_url_b ? true : false}
              helperText={errors.video_url_b}
              accept="video/webm video/mp4"
            />
            {/* thumbnail_image_url */}
            <TextField
              id="thumbnail_image_url"
              label="image_url"
              type="file"
              accept="image/jpeg"
              InputLabelProps={{ shrink: true }}
              //value={values.thumbnail_image_url}
              onChange={thumbnailImageChange}
              error={errors.thumbnail_image_url ? true : false}
              helperText={errors.thumbnail_image_url}
            />
            {/* video_package_name */}
            <TextField
              id="video_package_name"
              label="Video Package Name"
              // value={values.video_package_name}
              onChange={handleChange("video_package_name")}
              error={errors.video_package_name ? true : false}
              helperText={errors.video_package_name}
            />
            {/* main_type */}
            <FormControl>
              <InputLabel id="main_type">Exercise Type</InputLabel>
              <Select
                labelId="main_type"
                value={values.fk_exercise_type_name}
                label="Main Type"
                defaultValue=""
                onChange={handleChange("fk_exercise_type_name")}
                error={errors.fk_exercise_type_name ? true : false}
              >
                <MenuItem value="" disabled>
                  Value
                </MenuItem>
                {exeTypes?.exercise_types?.map((exe, index) => (
                  <MenuItem key={index} value={exe.exercise_type_name}>
                    {exe?.exercise_type_name}
                  </MenuItem>
                ))}
              </Select>
              {errors.fk_exercise_type_name && (
                <FormHelperText error>
                  {errors.fk_exercise_type_name}
                </FormHelperText>
              )}
            </FormControl>
            {/* package_type */}
            <FormControl>
              <InputLabel id="main_type">Subscription Level</InputLabel>
              <Select
                labelId="package_type"
                value={values.fk_user_subscription_level_id}
                defaultValue=""
                label="Subscription Level"
                onChange={handleChange("fk_user_subscription_level_id")}
                error={errors.fk_user_subscription_level_id ? true : false}
              >
                <MenuItem value="" disabled>
                  Value
                </MenuItem>
                {Array.isArray(subscriptionTypes.user_subscription_level)
                  ? subscriptionTypes.user_subscription_level.map((pt) => (
                      <MenuItem key={pt.id} value={pt.id}>
                        {pt.subscription_type}
                      </MenuItem>
                    ))
                  : null}
              </Select>
              {errors.fk_user_subscription_level_id && (
                <FormHelperText error>
                  {errors.fk_user_subscription_level_id}
                </FormHelperText>
              )}
            </FormControl>
            {/* experience */}
            <FormControl>
              <InputLabel id="experience">Experience</InputLabel>
              <Select
                labelId="experience"
                value={values.fk_experience_level_id}
                label="Experience"
                defaultValue=""
                onChange={handleChange("fk_experience_level_id")}
                error={errors.fk_experience_level_id ? true : false}
              >
                <MenuItem value="" disabled>
                  Value
                </MenuItem>
                {expLevels?.experience_level?.map((exe, index) => (
                  <MenuItem key={index} value={exe.id}>
                    {exe?.name}
                  </MenuItem>
                ))}
              </Select>
              {errors.fk_experience_level_id && (
                <FormHelperText error>
                  {errors.fk_experience_level_id}
                </FormHelperText>
              )}
            </FormControl>
            {/* duration */}
            <TextField
              type="number"
              id="duration_in_minute"
              label="duration_in_minute"
              value={values.duration_in_minute}
              onChange={handleChange("duration_in_minute")}
              error={errors.duration_in_minute ? true : false}
              helperText={errors.duration_in_minute}
            />

            {/* promotion */}
            <FormControl>
              <InputLabel id="promotion">Promotion</InputLabel>
              <Select
                labelId="promotion"
                // value={values.promotion}
                label="Promotion"
                defaultValue=""
                onChange={handleChange("promotion")}
                error={errors.promotion ? true : false}
              >
                <MenuItem value="" disabled>
                  Value
                </MenuItem>
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
              {errors.promotion && (
                <FormHelperText error>{errors.promotion}</FormHelperText>
              )}
            </FormControl>
            {/* sub type */}
            {/* <FormControl>
              <InputLabel id="demo-multiple-checkbox-label">
                Sub Type
              </InputLabel>
              <Select
                label="Sub Type"
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={muscleType}
                onChange={handleChangesMuscleType}
                input={<OutlinedInput label="Tag" />}
                renderValue={(selected) =>
                  selected.map((uuid) => getSubtypeNameById(uuid)).join(", ")
                }
                MenuProps={MenuProps}
              >
                {muslceTypess?.muscle_types?.map((name) => (
                  <MenuItem key={name.id} value={name.id}>
                    <CheckBox
                      checked={muscleType.indexOf(name.name) > -1}
                      // checked={muscleType.includes(name.id)}
                    />
                    <ListItemText primary={name.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
            <FormControl>
              <InputLabel id="demo-multiple-checkbox-label">
                Muscle Type
              </InputLabel>

              <Select
                label="Muscle Type"
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={muscleType}
                onChange={handleChangesMuscleType}
                input={<OutlinedInput label="Tag" />}
                renderValue={(selected) =>
                  selected.map((uuid) => getSubtypeNameById(uuid)).join(", ")
                }
                MenuProps={MenuProps}
              >
                {muslceTypess?.muscle_types?.map((names) => (
                  <MenuItem key={names.id} value={names.id}>
                    <Checkbox
                      // checked={subType.indexOf(name.name) > -1}
                      checked={muscleType.includes(names.id)}
                    />
                    <ListItemText primary={names.name} />
                  </MenuItem>
                ))}
              </Select>
              {errors.fk_muscle_type_id && (
                <FormHelperText error>
                  {errors.fk_muscle_type_id}
                </FormHelperText>
              )}
            </FormControl>

            {/* description */}
            <Box>
              <InputLabel style={{ marginBottom: 10, fontWeight: "bold" }}>
                Description
              </InputLabel>
              <RichTextEditor
                className="description-text"
                onChange={onChange}
                value={textValue}
                toolbarConfig={toolbarConfig}
              />
              {errors.description && (
                <FormHelperText error> {errors.description}</FormHelperText>
              )}
            </Box>

            {/* description MM*/}
            <Box>
              <InputLabel style={{ marginBottom: 10, fontWeight: "bold" }}>
                Description MM
              </InputLabel>
              <RichTextEditor
                className="description-text"
                //onChange={handleChange("description")}
                onChange={onChangeMM}
                value={textValueMM}
                toolbarConfig={toolbarConfig}
              />
              {errors.description_mm && (
                <FormHelperText error> {errors.description_mm}</FormHelperText>
              )}
            </Box>
          </div>
        </CardContent>

        <Box className="btn_end">
          <Button
            variant="contained"
            className="progress"
            size="large"
            sx={{ height: 50, width: 100 }}
            {...(loading && { loading: true })}
            onClick={handleCreate}
            //style={{ position: "absolute", top: "10px", left: "2px" }}
          >
            {loading ? (
              <CircularProgress
                color="warning"
                value={progress}
                text={`${progress}%`}
              />
            ) : (
              "Create"
            )}
          </Button>
        </Box>
      </Card>

      {showAlert.message && !showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="success"
        >
          {showAlert.message}
        </Alert>
      )}
      {showAlert.message && showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="error"
        >
          {showAlert.message}
        </Alert>
      )}
    </div>
  );
};
export default CreateVideo;
