import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";

import {
  Breadcrumbs,
  Typography,
  Box,
  Paper,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  ListItem,
  ListItemText,
  CardActions,
  Button,
  Modal,
  Alert,
} from "@mui/material";
import {
  DELETE_SUB_PACKAGE,
  SUB_PACKAGE_BY_PK,
} from "../../gql/subscriptionPakcage";
import {
  DELETE_SUB_PACKAGE_VARIANT,
  SUB_PACKAGE_VARIANT_BY_PK,
  UPDATE_PROMOTION,
} from "../../gql/subscriptionpackageVariant";
import UpdateSubPackageVariant from "../../components/subscriptionPackageVariant/UpdateSubPackageVariant";

const styleP = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  width: 300,
  backgroundColor: "#cecece",
  padding: "2rem",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100vw",
  height: "100vh",
  overflow: "scroll",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
};
const styleR = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const SubscriptionPackage = ({ homeAlert }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const result = useQuery(SUB_PACKAGE_VARIANT_BY_PK, { variables: { id: id } });
  const [showAlert, setShowAlert] = useState({ message: "", isError: false });
  const [openU, setOpenU] = useState(false);
  const [openR, setOpenR] = useState(false);
  const [modal, setModal] = useState(false);

  const [promotionDisable, setPormotionDisable] = useState(false);
  const sub_packageAlert = (message, isError = false) => {
    setShowAlert({ message: message, isError: isError });
    setTimeout(() => {
      setShowAlert({ message: "", isError: false });
    }, 3000);
  };

  const [deletePackageVariant] = useMutation(DELETE_SUB_PACKAGE_VARIANT, {
    onError: (error) => {
      console.log(error);
      setShowAlert({ message: "Error on server", isError: true });
      setTimeout(() => {
        setShowAlert({ message: "", isError: false });
      }, 3000);
    },
    onCompleted: () => {
      alert("Subscrption Package Variant has been removed");
      navigate(-1);
    },
  });

  const [updateEnable] = useMutation(UPDATE_PROMOTION, {
    onError: (err) => {
      console.log("Enable Error ", err);
    },
    onCompleted: (data) => {
      alert("Update Promotion");
    },
    refetchQueries: [SUB_PACKAGE_VARIANT_BY_PK],
  });

  useEffect(() => {
    setPormotionDisable(
      result?.data?.subscription_package_variants_by_pk?.disabled
    );
  }, [result?.data]);

  const handleDisableClose = () => {
    setModal(false);
  };

  const handleDisable = async () => {
    try {
      await updateEnable({
        variables: {
          id: result?.data?.subscription_package_variants_by_pk?.id,
          disabled: !promotionDisable,
        },
      });
      setPormotionDisable(!promotionDisable);
      setModal(false);
    } catch (err) {
      console.log("Update promotion erorr ", err);
    }
  };

  const handleOpenU = () => setOpenU(true);
  const handleCloseU = () => setOpenU(false);
  const handleOpenR = () => setOpenR(true);
  const handleCloseR = () => setOpenR(false);

  const sub_package = result?.data?.subscription_package_variants_by_pk;

  const handleDelete = () => {
    deletePackageVariant({ variables: { id: sub_package.id } });
  };
  if (result.loading) {
    return (
      <div>
        <em>Loading...</em>
      </div>
    );
  }
  return (
    <div>
      <div role="presentation">
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/user" className="dashboard">
            Dashboard
          </Link>
          <Link to="/sub_package" className="dashboard">
            Subscription Package
          </Link>
          <span>{id}</span>
        </Breadcrumbs>
      </div>
      <Typography variant="h6" component="h2" sx={{ my: 3, color: "black" }}>
        Subscription Package Variant Details
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexFlow: "wrap row",
          "& > :not(style)": {
            m: 1,
            width: "100%",
            minHeight: "25vh",
          },
        }}
      >
        <Paper elevation={3}>
          <Card>
            <CardHeader>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                Subscription Package Variant
              </Typography>
            </CardHeader>
            <CardContent sx={{ display: "flex", justifyContent: "center" }}>
              {/* {sub_package.title_image_url && sub_package.title_image_url !== "null" && (
                <CardMedia
                  // sx={{ flex: 1 }}
                  // component="img"
                  // height="300px"
                  // image={sub_package.title_image_url}
                  // alt="Product"
                  // className="card-media"

                  sx={{
                    flex: 1,
                    maxHeight: 300,
                    objectFit: "contain",
                    width: 300,
                    mt: 4,
                    boxShadow: 5,
                    borderRadius: 2,
                    borderColor: "white",
                  }}
                  component="img"
                  height="300"
                  image={sub_package.title_image_url}
                  alt="notification image"
                  className="grid_img"
                />
              )} */}
              <Box
                sx={{
                  flex: 2,
                  // mx: 1,
                  py: 1,
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <Box>
                  <ListItem>
                    <ListItemText primary="ID" secondary={sub_package?.id} />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Title Eng"
                      secondary={sub_package?.title_en}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Title Eng"
                      secondary={sub_package?.title_mm}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Description Eng"
                      secondary={sub_package?.description_en}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Description MM"
                      secondary={sub_package?.description_mm}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Price"
                      secondary={sub_package?.price}
                    />
                  </ListItem>
                </Box>
                <Box>
                  <ListItem>
                    <ListItemText
                      primary="Original Price"
                      secondary={sub_package?.original_price}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Discount Percentage"
                      secondary={sub_package?.discount_percentage}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Duration In Days"
                      secondary={sub_package?.durationInDays}
                    />
                  </ListItem>
                  {/* <ListItem>
                    <ListItemText
                      primary="IAP Product ID"
                      secondary={sub_package?.iap_product_id}
                    />
                  </ListItem> */}
                  <ListItem>
                    <ListItemText
                      primary="Created At"
                      secondary={sub_package?.created_at?.substring(0, 10)}
                    />
                  </ListItem>
                  {console.log("promtion ", sub_package?.disable_promotion)}
                  <ListItem>
                    <ListItemText
                      primary="Promotion Disable"
                      secondary={
                        sub_package?.disable_promotion === true ? "Yes" : "No"
                      }
                    />
                  </ListItem>
                </Box>
              </Box>
            </CardContent>

            <CardActions sx={{ justifyContent: "flex-end", p: "1rem" }}>
              <Box>
                <Button
                  variant="contained"
                  size="large"
                  color={sub_package?.disabled === true ? "error" : "warning"}
                  onClick={() => setModal(true)}
                >
                  {sub_package?.disabled === true ? "Enable" : "Disable"}
                </Button>
              </Box>
              <Box>
                <Button
                  color="primary"
                  variant="contained"
                  sx={{ mx: "2rem" }}
                  onClick={handleOpenU}
                >
                  Edit
                </Button>
                <Button variant="contained" color="error" onClick={handleOpenR}>
                  Remove
                </Button>
              </Box>
            </CardActions>
          </Card>
        </Paper>
      </Box>
      <Modal
        keepMounted
        open={openR}
        onClose={handleCloseR}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={styleR}>
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Confirmation
          </Typography>
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
            Are you sure want to remove this sub_package?
          </Typography>
          <Box sx={{ textAlign: "right", mt: 2 }}>
            <Button color="secondary" onClick={handleCloseR}>
              Cancel
            </Button>
            <Button onClick={handleDelete}>Confirm</Button>
          </Box>
        </Box>
      </Modal>
      <div style={{ minHeight: "auto" }}>
        <Modal
          open={openU}
          onClose={handleCloseU}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ width: "100vw" }}
        >
          <Box sx={style}>
            <UpdateSubPackageVariant
              packageVariant={sub_package}
              handleClose={handleCloseU}
              // sub_packageAlert={sub_packageAlert}
            />
          </Box>
        </Modal>
      </div>

      {/* Update Disable */}
      <Modal
        keepMounted
        open={modal}
        onClose={handleDisableClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box style={styleP} sx={{ px: 4, py: 4, borderColor: "black" }}>
          <Typography
            id="keep-mounted-modal-description"
            variant="body"
            sx={{ mt: 2 }}
          >
            Are you sure want to Disable?
          </Typography>
          <Box sx={{ textAlign: "right", mt: 2 }}>
            <Button color="primary" onClick={handleDisableClose}>
              Cancel
            </Button>
            <Button color="error" onClick={handleDisable}>
              Confirm
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Update Enable */}
      {/* <Modal
        keepMounted
        open={enable}
        onClose={handleEnableClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box style={styleP} sx={{ px: 4, py: 4, borderColor: "black" }}>
          <Typography
            id="keep-mounted-modal-description"
            variant="body"
            sx={{ mt: 2 }}
          >
            Are you sure want to Enable?
          </Typography>
          <Box sx={{ textAlign: "right", mt: 2 }}>
            <Button color="primary" onClick={handleEnableClose}>
              Cancel
            </Button>
            <Button color="error" onClick={handleEnable}>
              Confirm
            </Button>
          </Box>
        </Box>
      </Modal> */}
      {showAlert.message && !showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="success"
        >
          {showAlert.message}
        </Alert>
      )}
      {showAlert.message && showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="warning"
        >
          {showAlert.message}
        </Alert>
      )}
    </div>
  );
};

export default SubscriptionPackage;
