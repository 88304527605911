import { useLazyQuery, useQuery } from "@apollo/client";
import { useMutation } from "@apollo/client";

import RichTextEditor from "react-rte";
import {
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormHelperText,
  CardMedia,
  Alert,
  CircularProgress,
} from "@mui/material";
import { Box } from "@mui/system";

import { useState, useEffect } from "react";
import { CREATE_EXE_ROUTINE } from "../../gql/plan";

import { GET_IMAGE_UPLOAD_URL } from "../../gql/misc";
import imageService from "../../services/image";
import { useRef } from "react";

import {
  ALL_PLANS,
  EXE_TARGET,
  EXP_LEVEL,
  SUBSCRIPTION_TYPE,
} from "../../gql/mixed";

const fileTypes = [
  "image/apng",
  "image/bmp",
  "image/gif",
  "image/jpeg",
  "image/pjpeg",
  "image/png",
  "image/svg+xml",
  "image/tiff",
  "image/webp",
  "image/x-icon",
];
const imageType = "image/*";
const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: [
    "INLINE_STYLE_BUTTONS",
    "BLOCK_TYPE_BUTTONS",
    "LINK_BUTTONS",
    "BLOCK_TYPE_DROPDOWN",
    "HISTORY_BUTTONS",
  ],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "custom-css-class" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
};
const CreateExeRoutine = ({ handleClose, routineAlert }) => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});

  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState({ message: "", isError: false });

  const [selectedImage, setSelectedImage] = useState();
  const [imagePreview, setImagePreview] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [imageFileUrl, setImageFileUrl] = useState(null);

  const [textValue, setTextValue] = useState(RichTextEditor.createEmptyValue());
  const [textValueMM, setTextValueMM] = useState(
    RichTextEditor.createEmptyValue()
  );

  const { data: exeTargets } = useQuery(EXE_TARGET);
  const { data: expLevels } = useQuery(EXP_LEVEL);
  const { data: subscriptionTypes } = useQuery(SUBSCRIPTION_TYPE);

  const fileInputRef = useRef(null);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  // for image
  const [getImageUrl] = useMutation(GET_IMAGE_UPLOAD_URL, {
    onError: (error) => {
      console.log("imge errors", error);
      setShowAlert({ message: "Error on server", isError: true });
      setTimeout(() => {
        setShowAlert({ message: "", isError: false });
      }, 1000);
    },
    onCompleted: (result) => {
      // setImageFileUrl(result.getImageUploadUrl.imageUploadUrl);
      // setValues({
      //   ...values,
      //   image_url: `https://axra.sgp1.digitaloceanspaces.com/PowerPlay/${result.getImageUploadUrl.imageName}`,
      // });
      setLoading(false);
    },
  });

  const imageChange = async (e) => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0];
      setSelectedImage(img);
      setValues({ ...values, image_url: URL.createObjectURL(img) });
      if (!fileTypes.includes(img.type)) {
        setErrors({
          ...errors,
          image_url: "Please select image. (PNG, JPG, JPEG, GIF, ...)",
        });
        return;
      }
      if (img.size > 10485760) {
        setErrors({
          ...errors,
          image_url: "Image file size must be smaller than 10MB.",
        });
        return;
      }
    }
  };

  //for input box
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    delete errors[prop];
    setErrors(errors);
  };

  //for create routine
  const [createRoutine] = useMutation(CREATE_EXE_ROUTINE, {
    onError: (error) => {
      console.log("error : ", error);
      setLoading(false);
    },
    onCompleted: (result) => {
      setValues({});
      setTextValue(RichTextEditor.createEmptyValue());
      setErrors({});
      routineAlert("New Plan has been added");
      setLoading(false);
      handleClose();
    },
    refetchQueries: [ALL_PLANS],
  });

  const handleCreate = async () => {
    setLoading(true);
    setErrors({});
    let isErrorExit = false;
    let errorObject = {};
    if (!values.plan_name) {
      errorObject.plan_name = "Plan name is required";
      isErrorExit = true;
    }
    // if (!values.image_url) {
    //   errorObject.image_url = "Image field is required.";
    //   isErrorExit = true;
    // }
    if (!values.fk_user_subscription_level_id) {
      errorObject.fk_user_subscription_level_id =
        "Subscription level is required.";
      isErrorExit = true;
    }
    if (!values.fk_exercise_target_id) {
      errorObject.fk_exercise_target_id = "Exercise Target is required.";
      isErrorExit = true;
    }
    if (!values.fk_experience_level_id) {
      errorObject.fk_experience_level_id = "Experience Level is required.";
      isErrorExit = true;
    }

    if (!values.description) {
      errorObject.description = "Description is required";
      isErrorExit = true;
    }
    if (!values.description_mm) {
      errorObject.description_mm = "Description MM is required";
      isErrorExit = true;
    }

    if (!values.routine_days_in_a_week) {
      errorObject.routine_days_in_a_week = "routine days is required";
      isErrorExit = true;
    }

    if (isErrorExit) {
      setErrors(errorObject);
      console.log("error object", errorObject);
      setLoading(false);
      return;
    }

    try {
      //await imageService.uploadImage(imageFileUrl, imageFile);
      const res = await getImageUrl({ variables: { contentType: "image/*" } });
      console.log("res ", res);
      await imageService.uploadImage(
        res.data.getImageUploadUrl.imageUploadUrl,
        selectedImage
      );

      await createRoutine({
        variables: {
          ...values,
          image_url: `https://axra.sgp1.digitaloceanspaces.com/PowerPlay/${res.data.getImageUploadUrl.imageName}`,
        },
      });
    } catch (error) {
      console.log("error : ", error);
    }
  };

  //to clear data after clicking close btn
  const handleClosClearData = () => {
    setValues({});
    setErrors({});
    handleClose();
  };
  // for Description
  const onChange = (value) => {
    setTextValue(value);
    setValues({ ...values, description: value.toString("html") });
  };

  // for Description MM
  const onChangeMM = (value) => {
    setTextValueMM(value);
    setValues({ ...values, description_mm: value.toString("html") });
  };
  console.log("values ", values);
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          color: "black",
          bgcolor: "#cecece",
          borderTopRightRadius: 10,
          borderTopLeftRadius: 10,
          py: 2,
        }}
      >
        <Typography variant="h5" component="h2" color="black" sx={{ mx: 4 }}>
          Create Plans
        </Typography>
        <Button
          onClick={handleClosClearData}
          variant="contained"
          color="error"
          sx={{ mx: 4 }}
        >
          Close
        </Button>
      </Box>

      <Card sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
        <CardContent className="grid--2--cols">
          {/* image */}

          {values?.image_url ? (
            <Box className="grid-img">
              <CardMedia
                sx={{
                  flex: 1,
                  bgcolor: "#cecece",
                  objectFit: "contain",
                  mt: 4,
                  boxShadow: 5,
                  borderRadius: 2,
                }}
                component="img"
                height="300"
                image={values?.image_url}
                alt="exercise image"
                className="grid_img"
              />
            </Box>
          ) : (
            <Box className="grid-img">
              <CardMedia
                sx={{
                  flex: 1,
                  bgcolor: "#cecece",
                  objectFit: "contain",
                  mt: 4,
                  boxShadow: 5,
                  borderRadius: 2,
                }}
                component="img"
                height="300"
                className="grid_img"
              />
            </Box>
          )}

          {/* list-items */}
          <div className="grid-item grid--2--cols">
            <TextField
              id="plan_name"
              label="Plan Name"
              value={values.plan_name ? values.plan_name : ""}
              onChange={handleChange("plan_name")}
              error={errors.plan_name ? true : false}
              helperText={errors.plan_name}
            />
            {/* subscription level */}
            <FormControl>
              <InputLabel id="sub_type">Subscription Level</InputLabel>
              <Select
                labelId="subscription level"
                label="subscription_level"
                value={values.fk_user_subscription_level_id}
                defaultValue=""
                onChange={handleChange("fk_user_subscription_level_id")}
                error={errors.fk_user_subscription_level_id ? true : false}
              >
                <MenuItem value="" disabled>
                  Value
                </MenuItem>
                {subscriptionTypes?.user_subscription_level?.map((level) => (
                  <MenuItem value={level.id}>
                    {level.subscription_type}
                  </MenuItem>
                ))}
              </Select>
              {errors.fk_user_subscription_level_id && (
                <FormHelperText error>
                  {errors.fk_user_subscription_level_id}
                </FormHelperText>
              )}
            </FormControl>
            {/*image_url */}
            <TextField
              id="image_url"
              label="image_url"
              type="file"
              accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml"
              InputLabelProps={{ shrink: true }}
              //value={values.thumbnail_image_url}
              onChange={imageChange}
              error={errors.image_url ? true : false}
              helperText={errors.image_url}
              inputRef={fileInputRef}
            />

            {/*Exercise Type*/}
            <FormControl>
              <InputLabel id="sub_type">Exercise Target</InputLabel>
              <Select
                labelId="exercise_type"
                label="Exercise Type"
                value={
                  values.fk_exercise_target_id
                    ? values.fk_exercise_target_id
                    : ""
                }
                defaultValue=""
                onChange={handleChange("fk_exercise_target_id")}
                error={errors.fk_exercise_target_id ? true : false}
              >
                <MenuItem value="" disabled>
                  Value
                </MenuItem>
                {exeTargets?.exercise_targets?.map((level) => (
                  <MenuItem value={level.id}>{level.target_name}</MenuItem>
                ))}
              </Select>
              {errors.fk_exercise_target_id && (
                <FormHelperText error>
                  {errors.fk_exercise_target_id}
                </FormHelperText>
              )}
            </FormControl>

            {/*exeperice level*/}
            <FormControl>
              <InputLabel id="sub_type">Experience Level</InputLabel>
              <Select
                labelId="experience_level"
                label="Experience Level"
                value={
                  values.fk_experience_level_id
                    ? values.fk_experience_level_id
                    : ""
                }
                defaultValue=""
                onChange={handleChange("fk_experience_level_id")}
                error={errors.fk_experience_level_id ? true : false}
              >
                <MenuItem value="" disabled>
                  Value
                </MenuItem>
                {expLevels?.experience_level?.map((level) => (
                  <MenuItem value={level.id}>{level.name}</MenuItem>
                ))}
              </Select>
              {errors.fk_experience_level_id && (
                <FormHelperText error>
                  {errors.fk_experience_level_id}
                </FormHelperText>
              )}
            </FormControl>

            {/* routing_days_in_a_week */}
            <TextField
              type="number"
              id="routine_days_in_a_week"
              label="Routine Days In A Week"
              value={
                values.routine_days_in_a_week
                  ? values.routine_days_in_a_week
                  : ""
              }
              onChange={handleChange("routine_days_in_a_week")}
              error={errors.routine_days_in_a_week ? true : false}
              helperText={errors.routine_days_in_a_week}
            />

            {/* description */}

            <Box className="description">
              <InputLabel style={{ marginBottom: 10, fontWeight: "bold" }}>
                Description
              </InputLabel>
              <RichTextEditor
                className="description-text"
                onChange={onChange}
                value={textValue}
                toolbarConfig={toolbarConfig}
              />
              {errors.description && (
                <FormHelperText error>{errors.description}</FormHelperText>
              )}
            </Box>

            {/* description mm*/}
            <Box className="description">
              <InputLabel style={{ marginBottom: 10, fontWeight: "bold" }}>
                Description MM
              </InputLabel>
              <RichTextEditor
                className="description-text"
                onChange={onChangeMM}
                value={textValueMM}
                toolbarConfig={toolbarConfig}
              />
              {errors.description_mm && (
                <FormHelperText error>{errors.description_mm}</FormHelperText>
              )}
            </Box>
          </div>
        </CardContent>

        <Box className="btn_end">
          <Button
            variant="contained"
            //color="warning"
            size="large"
            sx={{ height: 50, width: 100 }}
            disabled={loading}
            onClick={handleCreate}
          >
            {/* {loading ? (
              <CircularProgress
                color="warning"
                value={progress}
                //text={`${progress}%`}
              />
            ) : (
              "Create"
            )} */}
            {loading ? <CircularProgress color="warning" /> : "Create"}
          </Button>
        </Box>
      </Card>
      {showAlert.message && !showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="success"
        >
          {showAlert.message}
        </Alert>
      )}
      {showAlert.message && showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="error"
        >
          {showAlert.message}
        </Alert>
      )}
    </div>
  );
};

export default CreateExeRoutine;
