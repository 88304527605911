import React, { useContext } from "react";
import { styled } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import InventoryIcon from "@mui/icons-material/Inventory";
import PlayCircleSharpIcon from "@mui/icons-material/PlayCircleSharp";
import ListItemIcon from "@mui/material/ListItemIcon";
import BrokenImageIcon from "@mui/icons-material/BrokenImage";
import AccountCircleSharpIcon from "@mui/icons-material/AccountCircleSharp";
import RunCircleSharpIcon from "@mui/icons-material/RunCircleSharp";
import CategorySharpIcon from "@mui/icons-material/CategorySharp";
import SegmentIcon from "@mui/icons-material/Segment";
import { Box, Divider } from "@mui/material";
import logo from "../static/PPlay_logo.png";
import { Link } from "react-router-dom";
import PaymentsIcon from "@mui/icons-material/Payments";
import "../style/App.css";
import SideBarContext from "../context/SideBarContext";
import ChangeCircleSharpIcon from "@mui/icons-material/ChangeCircleSharp";
import HistoryIcon from "@mui/icons-material/History";
import SlowMotionVideoSharpIcon from "@mui/icons-material/SlowMotionVideoSharp";
import AccessAlarmSharpIcon from "@mui/icons-material/AccessAlarmSharp";
import SportsKabaddiSharpIcon from "@mui/icons-material/SportsKabaddiSharp";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
const drawerWidth = 290;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const SideBar = ({ open }) => {
  const { nav, setNav } = useContext(SideBarContext);

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          backgroundColor: "#f7f7f7",
        },
      }}
      className="sidebar"
      variant="persistent"
      anchor="left"
      open={open}
    >
      <DrawerHeader>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            margin: "auto",
          }}
        >
          <img
            src={logo}
            alt="power play"
            height="28px"
            display="flex"
            justifyContent="center"
            margin="auto"
          />
        </Box>
      </DrawerHeader>
      <List className="nav-list" sx={{ padding: "1rem" }}>
        <Link to="/user" onClick={() => setNav("user")} className="nav-link">
          <ListItem button className={`nav-btn ${nav === "" && "active"}`}>
            <ListItemIcon>
              <AccountCircleSharpIcon
                sx={{ fontSize: "24px" }}
                className="nav-link-icon"
              />
            </ListItemIcon>
            Users
          </ListItem>
        </Link>
        <Divider sx={{ color: "orangered", my: "1rem" }} />
        <Link
          to="/video"
          onClick={() => {
            setNav("video");
          }}
          className="nav-link"
        >
          <ListItem button className={`nav-btn ${nav === "video" && "active"}`}>
            <ListItemIcon>
              <PlayCircleSharpIcon
                sx={{ fontSize: "24px" }}
                className="nav-link-icon"
              />
            </ListItemIcon>
            Video List
          </ListItem>
        </Link>
        {/* plan */}
        <Link
          to="/plan"
          onClick={() => {
            setNav("plan");
          }}
          className="nav-link"
        >
          <ListItem button className={`nav-btn ${nav === "plan" && "active"}`}>
            <ListItemIcon>
              <RunCircleSharpIcon
                sx={{ fontSize: "24px" }}
                className="nav-link-icon"
              />
            </ListItemIcon>
            Plans
          </ListItem>
        </Link>
        <Link
          to="/nuroutine"
          onClick={() => {
            setNav("nuroutine");
          }}
          className="nav-link"
        >
          <ListItem
            button
            className={`nav-btn ${nav === "nuroutine" && "active"}`}
          >
            <ListItemIcon>
              <ChangeCircleSharpIcon
                sx={{ fontSize: "24px" }}
                className="nav-link-icon"
              />
            </ListItemIcon>
            Nutrition Routine
          </ListItem>
        </Link>
        {/* SubType */}
        <Link
          to="/muscleType"
          onClick={() => {
            setNav("muscleType");
          }}
          className="nav-link"
        >
          <ListItem
            button
            className={`nav-btn ${nav === "muscleType" && "active"}`}
          >
            <ListItemIcon>
              <CategorySharpIcon
                sx={{ fontSize: "24px" }}
                className="nav-link-icon"
              />
            </ListItemIcon>
            Muscle Type
          </ListItem>
        </Link>

        {/* blog */}
        <Link
          to="/blog"
          onClick={() => {
            setNav("blog");
          }}
          className="nav-link"
        >
          <ListItem button className={`nav-btn ${nav === "blog" && "active"}`}>
            <ListItemIcon>
              <SegmentIcon
                sx={{ fontSize: "24px" }}
                className="nav-link-icon"
              />
            </ListItemIcon>
            Articles
          </ListItem>
        </Link>

        {/* Ads */}
        <Link
          to="/ads"
          onClick={() => {
            setNav("ads");
          }}
          className="nav-link"
        >
          <ListItem button className={`nav-btn ${nav === "ads" && "active"}`}>
            <ListItemIcon>
              <BrokenImageIcon
                sx={{ fontSize: "24px" }}
                className="nav-link-icon"
              />
            </ListItemIcon>
            Ads
          </ListItem>
        </Link>
        {/* subscription packages*/}
        <Link
          to="/sub_package"
          onClick={() => {
            setNav("sub_package");
          }}
          className="nav-link"
        >
          <ListItem
            button
            className={`nav-btn ${nav === "sub_package" && "active"}`}
          >
            <ListItemIcon>
              <InventoryIcon
                sx={{ fontSize: "24px" }}
                className="nav-link-icon"
              />
            </ListItemIcon>
            Subscription Package
          </ListItem>
        </Link>
        {/* banking acc*/}
        <Link
          to="/banking_acc"
          onClick={() => {
            setNav("banking_acc");
          }}
          className="nav-link"
        >
          <ListItem
            button
            className={`nav-btn ${nav === "banking_acc" && "active"}`}
          >
            <ListItemIcon>
              <MonetizationOnIcon
                sx={{ fontSize: "24px" }}
                className="nav-link-icon"
              />
            </ListItemIcon>
            Manual Payment
          </ListItem>
        </Link>
        <Divider sx={{ color: "orangered", my: "1rem" }} />
        {/* Subscription history */}
        <Link
          to="/subHistory"
          onClick={() => {
            setNav("subHistory");
          }}
          className="nav-link"
        >
          <ListItem
            button
            className={`nav-btn ${nav === "subHistory" && "active"}`}
          >
            <ListItemIcon>
              <SubscriptionsIcon
                sx={{ fontSize: "24px" }}
                className="nav-link-icon"
              />
            </ListItemIcon>
            Subscription History
          </ListItem>
        </Link>
        {/* Subscription history */}
        <Link
          to="/specialHistory"
          onClick={() => {
            setNav("specialHistory");
          }}
          className="nav-link"
        >
          <ListItem
            button
            className={`nav-btn ${nav === "specialHistory" && "active"}`}
          >
            <ListItemIcon>
              <HistoryIcon
                sx={{ fontSize: "24px" }}
                className="nav-link-icon"
              />
            </ListItemIcon>
            Special Package Purchase History
          </ListItem>
        </Link>
        {/* payment transaction */}
        <Link
          to="/transaction"
          onClick={() => {
            setNav("transaction");
          }}
          className="nav-link"
        >
          <ListItem
            button
            className={`nav-btn ${nav === "transaction" && "active"}`}
          >
            <ListItemIcon>
              <PaymentsIcon
                sx={{ fontSize: "24px" }}
                className="nav-link-icon"
              />
            </ListItemIcon>
            Pyament Transaction
          </ListItem>
        </Link>

        <Divider sx={{ color: "orangered", my: "1rem" }} />

        {/* special_trainer*/}
        <Link
          to="/special_trainer"
          onClick={() => {
            setNav("special_trainer");
          }}
          className="nav-link"
        >
          <ListItem
            button
            className={`nav-btn ${nav === "special_trainer" && "active"}`}
          >
            <ListItemIcon>
              <SportsKabaddiSharpIcon
                sx={{ fontSize: "24px" }}
                className="nav-link-icon"
              />
            </ListItemIcon>
            Special Trainer
          </ListItem>
        </Link>

        {/* special_package */}
        <Link
          to="/special_package"
          onClick={() => {
            setNav("special_package");
          }}
          className="nav-link"
        >
          <ListItem
            button
            className={`nav-btn ${nav === "special_package" && "active"}`}
          >
            <ListItemIcon>
              <AccessAlarmSharpIcon
                sx={{ fontSize: "24px" }}
                className="nav-link-icon"
              />
            </ListItemIcon>
            Special Package
          </ListItem>
        </Link>

        {/* special_Video */}
        <Link
          to="/special_video"
          onClick={() => {
            setNav("special_video");
          }}
          className="nav-link"
        >
          <ListItem
            button
            className={`nav-btn ${nav === "special_video" && "active"}`}
          >
            <ListItemIcon>
              <SlowMotionVideoSharpIcon
                sx={{ fontSize: "24px" }}
                className="nav-link-icon"
              />
            </ListItemIcon>
            Special Video
          </ListItem>
        </Link>

        {/* <Link
          to="/spe_exe_routine"
          onClick={() => {
            setNav("spe_exe_routine");
          }}
          className={`nav-link `}
        >
          <ListItem
            button
            className={`nav-btn ${nav === "spe_exe_routine" && "active"}`}
          >
            <ListItemIcon>
              <RunCircleSharpIcon
                sx={{ fontSize: "24px" }}
                className="nav-link-icon"
              />
            </ListItemIcon>
            Special Exercise Routine
          </ListItem>
        </Link>
        <Link
          to="/spe_nu_routine"
          onClick={() => {
            setNav("spe_nu_routine");
          }}
          className="nav-link"
        >
          <ListItem
            button
            className={`nav-btn ${nav === "spe_nu_routine" && "active"}`}
          >
            <ListItemIcon>
              <ChangeCircleSharpIcon
                sx={{ fontSize: "24px" }}
                className="nav-link-icon"
              />
            </ListItemIcon>
            Special Nutrition Routine
          </ListItem>
        </Link> */}
      </List>
    </Drawer>
  );
};

export default SideBar;
