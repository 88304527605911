import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Box } from "@mui/system";
import {
  Button,
  Typography,
  Card,
  CardContent,
  TextField,
  CircularProgress,
  Alert,
  InputLabel,
  FormHelperText,
} from "@mui/material";

import {
  ADD_SUB_PACKAGE_VARIANT,
  ALL_SUB_PACKAGE_VARIANT,
} from "../../gql/subscriptionpackageVariant";
import {
  ADD_BANKING_ACC,
  ALL_BANKING_ACC,
  ALL_PAYMENT_SERVICE_NAMES,
} from "../../gql/bankingAcc";
import RichTextEditor from "react-rte";
const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: [
    "INLINE_STYLE_BUTTONS",
    "BLOCK_TYPE_BUTTONS",
    "LINK_BUTTONS",
    "BLOCK_TYPE_DROPDOWN",
    "HISTORY_BUTTONS",
  ],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "custom-css-class" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
};
const CreateBankingAcc = ({ handleClose, packageId }) => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [showAlert, setShowAlert] = useState({ message: "", isError: false });
  const [information, setInformation] = useState(
    RichTextEditor.createEmptyValue()
  );
  const [informationSub, setInformationSub] = useState(
    RichTextEditor.createEmptyValue()
  );
  const [informationPoint, setInformationPoint] = useState(
    RichTextEditor.createEmptyValue()
  );
  const [progress, setProgress] = useState(0);

  const { data: existingNamesData } = useQuery(ALL_PAYMENT_SERVICE_NAMES);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const [createVideo] = useMutation(ADD_BANKING_ACC, {
    onError: (error) => {
      console.log("error : ", error);
      alert("Manual Payment Upload Error");
      setLoading(false);
    },
    onCompleted: (result) => {
      setValues({});
      setErrors({});

      setLoading(false);
      // videoAlert("New Video have been created.");
      alert("Manual Payment has been Created");
      handleClose();
    },
    refetchQueries: [ALL_BANKING_ACC],
  });

  const onChangeInformation = (value) => {
    setInformation(value);
    setValues({ ...values, information: value.toString("html") });
  };
  const onChangeInformationSub = (value) => {
    setInformationSub(value);
    setValues({ ...values, information_sub: value.toString("html") });
  };

  const onChangeInformationPoint = (value) => {
    setInformationPoint(value);
    setValues({ ...values, information_point: value.toString("html") });
  };

  const handleCreate = async () => {
    setLoading(true);
    setErrors({});
    let isErrorExit = false;
    let errorObject = {};

    if (!values.payment_service_name) {
      errorObject.payment_service_name = "Payment Service Name is required.";
      isErrorExit = true;
    }
    if (!values.receiver_name) {
      errorObject.receiver_name = "receiver_name  is required.";
      isErrorExit = true;
    }
    if (!values.account_number) {
      errorObject.account_number = "account_number  is required.";
      isErrorExit = true;
    }

    if (existingNamesData) {
      const existingNames = existingNamesData?.banking_accounts?.map(
        (acc) => acc.payment_service_name
      );
      if (existingNames.includes(values.payment_service_name)) {
        errorObject.payment_service_name =
          "Payment Service Name must be unique.";
        isErrorExit = true;
      }
    }

    if (isErrorExit) {
      console.log("eror obj ", errorObject);
      setErrors({ ...errorObject });
      setLoading(false);
      return;
    }

    try {
      await createVideo({
        variables: {
          addBankingAcc: {
            payment_service_name: values.payment_service_name,
            receiver_name: values.receiver_name,
            account_number: values.account_number,
            information: values?.information,
            information_sub: values?.information_sub,
            information_point: values?.information_point,
          },
        },
      });
    } catch (error) {
      console.log("error : ", error);
    }
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          color: "black",
          // width: "94.5vw",
          bgcolor: "#cecece",
          borderTopRightRadius: 10,
          borderTopLeftRadius: 10,
          py: 2,
        }}
      >
        <Typography variant="h5" component="h2" color="black" sx={{ mx: 4 }}>
          Create Manual Payment
        </Typography>
        <Button
          onClick={handleClose}
          variant="contained"
          color="error"
          sx={{ mx: 4 }}
        >
          Close
        </Button>
      </Box>
      <Card
        sx={{
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }}
      >
        <CardContent>
          {/* list items */}
          <div
            className="grid--2--cols grid-item"
            style={{
              columnGap: "5rem",
              rowGap: "2rem",
              paddingLeft: "2rem",
              paddingRight: "2rem",
            }}
          >
            {/* title en */}
            <TextField
              id="payment_service_name"
              label="Payment Service Name"
              // value={values.video_package_name}
              onChange={handleChange("payment_service_name")}
              error={errors.payment_service_name ? true : false}
              helperText={errors.payment_service_name}
            />
            {/* receiver_name*/}
            <TextField
              id="receiver_name"
              label="Receiver Name"
              // value={values.video_package_name}
              onChange={handleChange("receiver_name")}
              error={errors.receiver_name ? true : false}
              helperText={errors.receiver_name}
            />
            {/*description */}
            <TextField
              id="account_number"
              label="Account Number"
              value={values.account_number}
              onChange={handleChange("account_number")}
              error={errors.account_number ? true : false}
              helperText={errors.account_number}
            />
            <div></div>

            {/*Information*/}
            <Box>
              <InputLabel style={{ marginBottom: 10, fontWeight: "bold" }}>
                Information Shopping
              </InputLabel>
              <RichTextEditor
                className="description-text"
                //onChange={handleChange("description")}
                onChange={onChangeInformation}
                value={information}
                toolbarConfig={toolbarConfig}
              />
              {errors.information && (
                <FormHelperText error> {errors.information}</FormHelperText>
              )}
            </Box>
            {/*Information sub*/}
            <Box>
              <InputLabel style={{ marginBottom: 10, fontWeight: "bold" }}>
                Information Subscription
              </InputLabel>
              <RichTextEditor
                className="description-text"
                //onChange={handleChange("description")}
                onChange={onChangeInformationSub}
                value={informationSub}
                toolbarConfig={toolbarConfig}
              />
              {errors.information_sub && (
                <FormHelperText error> {errors.information_sub}</FormHelperText>
              )}
            </Box>
            {/*Information point*/}
            <Box>
              <InputLabel style={{ marginBottom: 10, fontWeight: "bold" }}>
                Information Point
              </InputLabel>
              <RichTextEditor
                className="description-text"
                //onChange={handleChange("description")}
                onChange={onChangeInformationPoint}
                value={informationPoint}
                toolbarConfig={toolbarConfig}
              />
              {errors.information_point && (
                <FormHelperText error>
                  {" "}
                  {errors.information_point}
                </FormHelperText>
              )}
            </Box>
          </div>
        </CardContent>

        <Box className="btn_end">
          <Button
            variant="contained"
            className="progress"
            size="large"
            sx={{ height: 50, width: 100 }}
            {...(loading && { loading: true })}
            onClick={handleCreate}
            //style={{ position: "absolute", top: "10px", left: "2px" }}
          >
            {loading ? (
              <CircularProgress
                color="warning"
                value={progress}
                text={`${progress}%`}
              />
            ) : (
              "Create"
            )}
          </Button>
        </Box>
      </Card>

      {showAlert.message && !showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="success"
        >
          {showAlert.message}
        </Alert>
      )}
      {showAlert.message && showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="error"
        >
          {showAlert.message}
        </Alert>
      )}
    </div>
  );
};
export default CreateBankingAcc;
