import React, { useRef, useState } from "react";
import imageService from "../../services/image";
import { useMutation, useQuery } from "@apollo/client";
import { GET_IMAGE_UPLOAD_URL } from "../../gql/misc";
import RichTextEditor from "react-rte";

import {
  Box,
  FormControl,
  TextField,
  Typography,
  CardMedia,
  Alert,
  InputLabel,
  FormHelperText,
  Button,
  CircularProgress,
  Select,
  MenuItem,
  Card,
  CardContent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";

import { useNavigate, useParams } from "react-router-dom";
import { ADD_ROUTINE } from "../../gql/routines";
import { ALL_PLANS } from "../../gql/mixed";

const fileTypes = [
  "image/apng",
  "image/bmp",
  "image/gif",
  "image/jpeg",
  "image/pjpeg",
  "image/png",
  "image/svg+xml",
  "image/tiff",
  "image/webp",
  "image/x-icon",
];

const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: [
    "INLINE_STYLE_BUTTONS",
    "BLOCK_TYPE_BUTTONS",
    "LINK_BUTTONS",
    "BLOCK_TYPE_DROPDOWN",
    "HISTORY_BUTTONS",
  ],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "custom-css-class" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
};
const CreateRoutine = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState({ message: "", isError: false });
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});

  const [selectedImage, setSelectedImage] = useState();
  const [imagePreview, setImagePreview] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [imageFileUrl, setImageFileUrl] = useState(null);

  const fileInputRef = useRef(null);
  {
  }
  const { data: plans } = useQuery(ALL_PLANS, { variables: { id: id } });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const imageChange = async (e) => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0];
      setSelectedImage(img);
      setValues({ ...values, image_url: URL.createObjectURL(img) });
      if (!fileTypes.includes(img.type)) {
        setErrors({
          ...errors,
          image_url: "Please select image. (PNG, JPG, JPEG, GIF, ...)",
        });
        return;
      }
      if (img.size > 10485760) {
        setErrors({
          ...errors,
          image_url: "Image file size must be smaller than 10MB.",
        });
        return;
      }
    }
  };

  const [getImageUrl] = useMutation(GET_IMAGE_UPLOAD_URL, {
    onError: (error) => {
      setShowAlert({ message: "Error on server", isError: true });
      setTimeout(() => {
        setShowAlert({ message: "", isError: false });
      }, 1000);
    },
    onCompleted: (result) => {
      setLoading(false);
      // setImageFileUrl(result.getImageUploadUrl.imageUploadUrl);
      // setValues({
      //   ...values,
      //   image_url: `https://axra.sgp1.digitaloceanspaces.com/PowerPlay/${result.getImageUploadUrl.imageName}`,
      // });
    },
  });

  const [CreateRoutine] = useMutation(ADD_ROUTINE, {
    onError: (error) => {
      console.log("error : ", error);
      alert("Routine Upload Error", error);
      setShowAlert({ message: "Error on server", isError: true });
      setTimeout(() => {
        setShowAlert({ message: "", isError: false });
      }, 1000);
      setLoading(false);
    },
    onCompleted: () => {
      setValues({});
      setErrors({});
      alert("Routine has been created");

      setLoading(false);
      setShowAlert({ message: "Blog have been created.", isError: false });
      setTimeout(() => {
        setShowAlert({ message: "", isError: false });
      }, 1000);
    },
  });

  const handleCreate = async () => {
    setLoading(true);
    setErrors({});
    let isErrorExit = false;
    let errorObject = {};
    if (!values.routine_name) {
      errorObject.routine_name = "Routine Name field is required.";
      isErrorExit = true;
    }

    // if (!values.image_url || !imageFile) {
    //   errorObject.image_url = "Image field is required.";
    //   isErrorExit = true;
    // }
    if (isErrorExit) {
      setErrors({ ...errorObject });
      setLoading(false);
      return;
    }
    try {
      // await imageService.uploadImage(imageFileUrl, imageFile);
      const res = await getImageUrl({ variables: { contentType: "image/*" } });

      await imageService.uploadImage(
        res.data.getImageUploadUrl.imageUploadUrl,
        selectedImage
      );
      await CreateRoutine({
        variables: {
          ...values,
          fk_plan_id: plans?.plans_by_pk?.id,
          image_url: `https://axra.sgp1.digitaloceanspaces.com/PowerPlay/${res.data.getImageUploadUrl.imageName}`,
        },
      });
      navigate(-1);
    } catch (error) {
      console.log("error : ", error);
    }
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          color: "black",
          bgcolor: "#cecece",
          borderTopRightRadius: 10,
          borderTopLeftRadius: 10,
          py: 2,
        }}
      >
        <Typography variant="h5" component="h2" color="black" sx={{ mx: 4 }}>
          Create Routine
        </Typography>
        <Button
          onClick={() => navigate(-1)}
          variant="contained"
          color="error"
          sx={{ mx: 4 }}
        >
          Close
        </Button>
      </Box>

      <Card sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
        <CardContent className="grid--2--cols">
          {/* image */}

          {values?.image_url ? (
            <Box className="grid-img">
              <CardMedia
                sx={{
                  flex: 1,
                  bgcolor: "#cecece",
                  objectFit: "contain",
                  mt: 4,
                  boxShadow: 5,
                  borderRadius: 2,
                }}
                component="img"
                height="300"
                image={values?.image_url}
                alt="exercise image"
                className="grid_img"
              />
            </Box>
          ) : (
            <Box className="grid-img">
              <CardMedia
                sx={{
                  flex: 1,
                  bgcolor: "#cecece",
                  objectFit: "contain",
                  mt: 4,
                  boxShadow: 5,
                  borderRadius: 2,
                }}
                component="img"
                height="300"
                className="grid_img"
              />
            </Box>
          )}

          <div className="grid-item grid--2--cols">
            {/* thumbnail_image_url */}
            <TextField
              id="image_url"
              label="image_url"
              type="file"
              accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml"
              InputLabelProps={{ shrink: true }}
              //value={values.thumbnail_image_url}
              onChange={imageChange}
              error={errors.image_url ? true : false}
              helperText={errors.image_url}
              inputRef={fileInputRef}
            />
            {/* routine name */}
            <TextField
              id="title"
              label="Routine Name"
              value={values.routine_name ? values.routine_name : ""}
              onChange={handleChange("routine_name")}
              error={errors.routine_name ? true : false}
              helperText={errors.routine_name}
            />
            {/* plan_name */}

            <TextField
              id="title"
              label="Plan Name"
              value={plans?.plans_by_pk?.plan_name}
              // onChange={handleChange("routine_name")}
              //error={errors.routine_name ? true : false}
              //helperText={errors.routine_name}
            />
          </div>
        </CardContent>
        <Box className="btn_end">
          <Button
            variant="contained"
            //color="warning"
            size="large"
            sx={{ height: 50, width: 100 }}
            disabled={loading}
            onClick={handleCreate}
          >
            {loading ? <CircularProgress color="warning" /> : "Create"}
          </Button>
        </Box>
      </Card>
      {showAlert.message && !showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="success"
        >
          {showAlert.message}
        </Alert>
      )}
      {showAlert.message && showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="error"
        >
          {showAlert.message}
        </Alert>
      )}
    </div>
  );
};

export default CreateRoutine;
