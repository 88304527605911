import React, { useEffect, useRef, useState } from "react";
import { useMutation } from "@apollo/client";
import { Box } from "@mui/system";
import {
  Button,
  Typography,
  Card,
  CardContent,
  TextField,
  CircularProgress,
  Alert,
  InputLabel,
  FormHelperText,
  CardMedia,
} from "@mui/material";

import imageCompression from "browser-image-compression";
import imageService from "../../services/image";
import {
  ADD_BANKING_ACC,
  ALL_BANKING_ACC,
  BANKING_ACC_BY_PK,
  EDIT_BANKING_ACC,
} from "../../gql/bankingAcc";
import RichTextEditor from "react-rte";
import { DELETE_IMAGE, GET_IMAGE_UPLOAD_URL } from "../../gql/misc";
const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: [
    "INLINE_STYLE_BUTTONS",
    "BLOCK_TYPE_BUTTONS",
    "LINK_BUTTONS",
    "BLOCK_TYPE_DROPDOWN",
    "HISTORY_BUTTONS",
  ],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "custom-css-class" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
};
const fileTypes = [
  "image/apng",
  "image/bmp",
  "image/gif",
  "image/jpeg",
  "image/pjpeg",
  "image/png",
  "image/svg+xml",
  "image/tiff",
  "image/webp",
  "image/x-icon",
];
const UpdateBankingAcc = ({ handleClose, bankingAcc }) => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [showAlert, setShowAlert] = useState({ message: "", isError: false });
  const [imagePreview, setImagePreview] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [imageFileUrl, setImageFileUrl] = useState(null);
  const [isImageChange, setIsImageChange] = useState(false);
  const [oldImageName, setOldImageName] = useState(null);

  const [information, setInformation] = useState(
    RichTextEditor.createEmptyValue()
  );
  const [informationSub, setInformationSub] = useState(
    RichTextEditor.createEmptyValue()
  );
  const [informationPoint, setInformationPoint] = useState(
    RichTextEditor.createEmptyValue()
  );
  const [progress, setProgress] = useState(0);
  const fileInputRef = useRef(null);

  useEffect(() => {
    let banking = bankingAcc;
    setValues({
      id: banking?.id,
      payment_service_name: banking.payment_service_name
        ? banking.payment_service_name
        : null,
      receiver_name: banking.receiver_name,
      account_number: banking.account_number,
      information: banking.information,
      information_point: banking.information_point,
      information_sub: banking.information_sub,
      qr_image_url: banking.qr_image_url,
    });

    setImagePreview(banking.qr_image_url);
    setImagePreview(banking.qr_image_url);
    let image_url = banking.qr_image_url;
    setOldImageName(
      image_url?.substring(image_url.lastIndexOf("/") + 1, image_url.lenght)
    );
    setInformation(
      RichTextEditor.createValueFromString(banking.information, "html")
    );
    setInformationSub(
      RichTextEditor.createValueFromString(banking.information_sub, "html")
    );
    setInformationPoint(
      RichTextEditor.createValueFromString(banking.information_point, "html")
    );
  }, [bankingAcc]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const [updateBanking] = useMutation(EDIT_BANKING_ACC, {
    onError: (error) => {
      console.log("error : ", error);
      alert("Manual Payment Upload Error");
      setLoading(false);
    },
    onCompleted: (result) => {
      setValues({});
      setErrors({});

      setLoading(false);
      alert("Manual Payment has been Update");
      handleClose();
    },
    refetchQueries: [BANKING_ACC_BY_PK],
  });

  const onChangeInformation = (value) => {
    setInformation(value);
    setValues({ ...values, information: value.toString("html") });
  };
  const onChangeInformationSub = (value) => {
    setInformationSub(value);
    setValues({ ...values, information_sub: value.toString("html") });
  };

  const onChangeInformationPoint = (value) => {
    setInformationPoint(value);
    setValues({ ...values, information_point: value.toString("html") });
  };

  const [getImageUrl] = useMutation(GET_IMAGE_UPLOAD_URL, {
    onError: (error) => {
      console.log("error : ", error);
    },
    onCompleted: (result) => {
      setImageFileUrl(result.getImageUploadUrl.imageUploadUrl);
      setIsImageChange(true);
      setValues({
        ...values,
        qr_image_url: `https://axra.sgp1.digitaloceanspaces.com/PowerPlay/${result.getImageUploadUrl.imageName}`,
      });
    },
  });
  const [deleteImage] = useMutation(DELETE_IMAGE, {
    onError: (error) => {
      console.log("error : ", error);
      setLoading(false);
    },
  });
  const imageChange = async (e) => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0];
      if (!fileTypes.includes(img.type)) {
        setErrors({
          ...errors,
          qr_image_url: "Please select image. (PNG, JPG, JPEG, GIF, ...)",
        });
        return;
      }
      if (img.size > 10485760) {
        setErrors({
          ...errors,
          qr_image_url: "Image file size must be smaller than 10MB.",
        });
        return;
      }
      const options = {
        initialQuality: 0.5,
        maxWidthOrHeight: 720,
        useWebWorker: true,
      };
      const compressedImage = await imageCompression(img, options);
      setImageFile(compressedImage);
      setImagePreview(URL.createObjectURL(compressedImage));
      getImageUrl({ variables: { contentType: "image" } });
    }
  };

  const handleUpdate = async () => {
    setLoading(true);
    setErrors({});
    let isErrorExit = false;
    let errorObject = {};

    if (isErrorExit) {
      setErrors({ ...errorObject });
      setLoading(false);
      return;
    }

    try {
      if (isImageChange) {
        await imageService.uploadImage(imageFileUrl, imageFile);
        await deleteImage({ variables: { image_name: oldImageName } });
      }
      await updateBanking({
        variables: {
          id: values?.id,
          editBankingAcc: {
            payment_service_name: values.payment_service_name,
            receiver_name: values.receiver_name,
            account_number: values.account_number,
            information: values?.information,
            information_sub: values?.information_sub,
            information_point: values?.information_point,
            qr_image_url: values?.qr_image_url,
          },
        },
      });
    } catch (error) {
      console.log("error : ", error);
    }
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          color: "black",
          // width: "94.5vw",
          bgcolor: "#cecece",
          borderTopRightRadius: 10,
          borderTopLeftRadius: 10,
          py: 2,
        }}
      >
        <Typography variant="h5" component="h2" color="black" sx={{ mx: 4 }}>
          Update Manual Payment
        </Typography>
        <Button
          onClick={handleClose}
          variant="contained"
          color="error"
          sx={{ mx: 4 }}
        >
          Close
        </Button>
      </Box>
      <Card
        sx={{
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }}
      >
        <CardContent>
          <Box className="grid-img" sx={{ mb: "2rem" }}>
            <CardMedia
              sx={{
                flex: 1,
                bgcolor: "#cecece",
                objectFit: "contain",
                mt: 4,
                boxShadow: 5,
                borderRadius: 2,
              }}
              component="img"
              height="300"
              image={imagePreview}
              alt="QR image"
              className="grid_img"
            />
            <Typography sx={{ textAlign: "center", my: "10px", color: "blue" }}>
              QR Image
            </Typography>
          </Box>

          {/* list items */}
          <div
            className="grid--2--cols grid-item"
            style={{
              columnGap: "5rem",
              rowGap: "2rem",
              paddingLeft: "2rem",
              paddingRight: "2rem",
            }}
          >
            {/* qr_image_url */}
            <TextField
              id="qr_image_url"
              label="QR Image"
              type="file"
              accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml"
              InputLabelProps={{ shrink: true }}
              //value={values.thumbnail_image_url}
              onChange={imageChange}
              error={errors.qr_image_url ? true : false}
              helperText={errors.qr_image_url}
              inputRef={fileInputRef}
            />
            {/* title en */}
            <TextField
              id="payment_service_name"
              label="Payment Service Name"
              value={values.payment_service_name}
              onChange={handleChange("payment_service_name")}
              error={errors.payment_service_name ? true : false}
              helperText={errors.payment_service_name}
            />
            {/* receiver_name*/}
            <TextField
              id="receiver_name"
              label="Receiver Name"
              value={values.receiver_name}
              onChange={handleChange("receiver_name")}
              error={errors.receiver_name ? true : false}
              helperText={errors.receiver_name}
            />
            {/*description */}
            <TextField
              id="account_number"
              label="Account Number"
              value={values.account_number}
              onChange={handleChange("account_number")}
              error={errors.account_number ? true : false}
              helperText={errors.account_number}
            />

            {/*Information*/}
            <Box>
              <InputLabel style={{ marginBottom: 10, fontWeight: "bold" }}>
                Information Shopping
              </InputLabel>
              <RichTextEditor
                className="description-text"
                //onChange={handleChange("description")}
                onChange={onChangeInformation}
                value={information}
                toolbarConfig={toolbarConfig}
              />
              {errors.information && (
                <FormHelperText error> {errors.information}</FormHelperText>
              )}
            </Box>
            {/*Information sub*/}
            <Box>
              <InputLabel style={{ marginBottom: 10, fontWeight: "bold" }}>
                Information Subscription
              </InputLabel>
              <RichTextEditor
                className="description-text"
                //onChange={handleChange("description")}
                onChange={onChangeInformationSub}
                value={informationSub}
                toolbarConfig={toolbarConfig}
              />
              {errors.information_sub && (
                <FormHelperText error> {errors.information_sub}</FormHelperText>
              )}
            </Box>
            {/*Information point*/}
            <Box>
              <InputLabel style={{ marginBottom: 10, fontWeight: "bold" }}>
                Information Point
              </InputLabel>
              <RichTextEditor
                className="description-text"
                //onChange={handleChange("description")}
                onChange={onChangeInformationPoint}
                value={informationPoint}
                toolbarConfig={toolbarConfig}
              />
              {errors.information_point && (
                <FormHelperText error>
                  {" "}
                  {errors.information_point}
                </FormHelperText>
              )}
            </Box>
          </div>
        </CardContent>

        <Box className="btn_end">
          <Button
            variant="contained"
            className="progress"
            size="large"
            sx={{ height: 50, width: 100 }}
            {...(loading && { loading: true })}
            onClick={handleUpdate}
            //style={{ position: "absolute", top: "10px", left: "2px" }}
          >
            {loading ? (
              <CircularProgress
                color="warning"
                value={progress}
                text={`${progress}%`}
              />
            ) : (
              "Update"
            )}
          </Button>
        </Box>
      </Card>

      {showAlert.message && !showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="success"
        >
          {showAlert.message}
        </Alert>
      )}
      {showAlert.message && showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="error"
        >
          {showAlert.message}
        </Alert>
      )}
    </div>
  );
};
export default UpdateBankingAcc;
