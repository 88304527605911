import { gql } from "@apollo/client";

export const BLOGS = gql`
  query MyQuery($search: String!, $limit: Int!, $offset: Int!) {
    blog(
      limit: $limit
      offset: $offset
      order_by: { updated_at: desc }
      where: { title: { _ilike: $search } }
    ) {
      created_at
      body
      category
      id
      title
      title_image_url
      updated_at
    }
    blog_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const CREATE_BLOG = gql`
  mutation MyMutation(
    $body: String!
    $body_mm: String!
    $title: String!
    $title_image_url: String!
    $thumbnail_image_url: String!
  ) {
    insert_blog_one(
      object: {
        body: $body
        body_mm: $body_mm
        category: ""
        title: $title
        title_image_url: $title_image_url
        thumbnail_image_url: $thumbnail_image_url
      }
    ) {
      body
      body_mm
      title
      title_image_url
    }
  }
`;

export const BLOG = gql`
  query Blog($id: uuid!) {
    blog_by_pk(id: $id) {
      body
      body_mm
      created_at
      id
      title
      thumbnail_image_url
      title_image_url
      updated_at
    }
  }
`;

export const UPDATE_BLOG = gql`
  mutation Update_Blog_By_Id(
    $id: uuid!
    $title: String!
    $body: String!
    $body_mm: String!
    $title_image_url: String!
    $thumbnail_image_url: String!
  ) {
    update_blog_by_pk(
      pk_columns: { id: $id }
      _set: {
        body: $body
        body_mm: $body_mm
        title: $title
        title_image_url: $title_image_url
        thumbnail_image_url: $thumbnail_image_url
      }
    ) {
      body
      body_mm
      created_at
      id
      title
      title_image_url
      updated_at
    }
  }
`;

export const DELETE_BLOG = gql`
  mutation Delete_Blog_by_pk($id: uuid!, $image_name: String!) {
    delete_blog_by_pk(id: $id) {
      id
    }
    deleteImage(imageName: $image_name) {
      error
      message
    }
  }
`;

export const UPDATE_POSITION = gql`
  mutation Update_Blog_By_Id($id: uuid!, $updateAt: timestamptz!) {
    update_blog_by_pk(
      pk_columns: { id: $id }
      _set: { updated_at: $updateAt }
    ) {
      body
      created_at
      id
      title
      title_image_url
      updated_at
    }
  }
`;
