import { gql } from "@apollo/client";
export const ALL_PLANS = gql`
  query aa($id: uuid!) {
    plans_by_pk(id: $id) {
      id
      plan_name
    }
  }
`;

//get all routines
export const ALL_ROUTINES = gql`
  query aa($id: uuid!) {
    routines_by_pk(id: $id) {
      id
      routine_name
    }
  }
`;

//get all video list
export const ALL_VIDOE = gql`
  query aa {
    video_list {
      id
      video_package_name
      video_id
    }
  }
`;
//get exercise target
export const EXE_TARGET = gql`
  query aa {
    exercise_targets {
      target_name
      id
    }
  }
`;

//get exe_type
export const EXE_TYPE = gql`
  query aa {
    exercise_types {
      exercise_type_name
      id
    }
  }
`;

//get exp_level
export const EXP_LEVEL = gql`
  query aa {
    experience_level {
      id
      name
    }
  }
`;

//get muscle type
export const MUSCLE_TYPE = gql`
  query aa {
    muscle_types {
      id
      name
    }
  }
`;

//get subscription type
export const SUBSCRIPTION_TYPE = gql`
  query aa {
    user_subscription_level {
      subscription_type
      id
    }
  }
`;
