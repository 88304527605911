import React, { useState } from "react";
import imageService from "../../services/image";
import { useMutation } from "@apollo/client";
import { GET_IMAGE_UPLOAD_URL } from "../../gql/misc";
import { CREATE_ADS } from "../../gql/ads";

import {
  Box,
  Card,
  CardContent,
  FormControl,
  TextField,
  CardMedia,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Navigate, useNavigate } from "react-router-dom";
import { ADD_MUSCLE_TYPE } from "../../gql/muscleTypes";

const fileTypes = [
  "image/apng",
  "image/bmp",
  "image/gif",
  "image/jpeg",
  "image/pjpeg",
  "image/png",
  "image/svg+xml",
  "image/tiff",
  "image/webp",
  "image/x-icon",
];

const CreateMuscleType = ({ handleClose, adsAlert }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [imagePreview, setImagePreview] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [imageFileUrl, setImageFileUrl] = useState(null);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const [getImageUrl] = useMutation(GET_IMAGE_UPLOAD_URL, {
    onError: (error) => {
      console.log("error : ", error);
    },
    onCompleted: (result) => {
      console.log(result);
      setImageFileUrl(result.getImageUploadUrl.imageUploadUrl);
      setValues({
        ...values,
        image_url: `https://axra.sgp1.digitaloceanspaces.com/PowerPlay/${result.getImageUploadUrl.imageName}`,
      });
    },
  });

  const [createAds] = useMutation(ADD_MUSCLE_TYPE, {
    onError: (error) => {
      console.log("error : ", error);
      setLoading(false);
    },
    onCompleted: () => {
      setValues({});
      setErrors({});
      setImageFile("");
      setImagePreview("");
      setLoading(false);
      // adsAlert("New Ads have been created.");
      alert("New Muscle Type have been created.");
      navigate(-1);
      handleClose();
    },
  });

  const imageChange = async (e) => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0];
      if (!fileTypes.includes(img.type)) {
        setErrors({
          ...errors,
          image_url: "Please select image. (PNG, JPG, JPEG, GIF, ...)",
        });
        return;
      }
      if (img.size > 10485760) {
        setErrors({
          ...errors,
          image_url: "Image file size must be smaller than 10MB.",
        });
        return;
      }
      setImageFile(img);
      setImagePreview(URL.createObjectURL(img));
      getImageUrl({ variables: { contentType: "image" } });
    }
  };

  const handleCreate = async () => {
    setLoading(true);
    setErrors({});
    let isErrorExit = false;
    let errorObject = {};

    if (!values.image_url || !imageFile) {
      errorObject.image_url = "Image field is required.";
      isErrorExit = true;
    }

    if (isErrorExit) {
      console.log("error", errorObject);
      setErrors({ ...errorObject });
      setLoading(false);
      return;
    }
    try {
      await imageService.uploadImage(imageFileUrl, imageFile);
      await createAds({ variables: { ...values } });
    } catch (error) {
      console.log("error : ", error);
    }
  };
  //console.log(values);
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          color: "black",
          bgcolor: "#cecece",
          borderTopRightRadius: 10,
          borderTopLeftRadius: 10,
          py: 2,
        }}
      >
        <Typography variant="h5" component="h2" color="black" sx={{ mx: 4 }}>
          Create Muscle Type
        </Typography>
        <Button
          onClick={() => navigate(-1)}
          variant="contained"
          color="error"
          sx={{ mx: 4 }}
        >
          Close
        </Button>
      </Box>
      <Card sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box
          sx={{
            display: "inline-flex",
            flexDirection: "column",
            flex: 1,
            my: 4,
            mx: 2,
          }}
        >
          <CardMedia
            component="img"
            image={imagePreview}
            alt="Ads image"
            sx={{
              flex: 1,
              bgcolor: "#cecece",
              maxHeight: 300,
              objectFit: "contain",
              borderRadius: 2,
            }}
          />
        </Box>
        <CardContent sx={{ flex: 3 }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <FormControl sx={{ m: 2 }}>
              <TextField
                id="image"
                placeholder="Upload image"
                InputLabelProps={{ shrink: true }}
                label="Upload Image"
                onChange={imageChange}
                error={errors.image_url ? true : false}
                helperText={errors.image_url}
                type="file"
                accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml"
              />
            </FormControl>
            <FormControl sx={{ m: 2 }} variant="outlined">
              <TextField
                id="name"
                label="Name"
                value={values.name}
                onChange={handleChange("name")}
                error={errors.name ? true : false}
                helperText={errors.name}
              />
            </FormControl>

            <FormControl sx={{ m: 2 }} variant="outlined">
              {/* <LoadingButton
                variant="contained"
                loading={loading}
                onClick={handleCreate}
                sx={{ backgroundColor: "#4b26d1", alignSelf: "end" }}
              >
                Create
              </LoadingButton> */}
              <Button
                variant="contained"
                //color="warning"
                size="large"
                sx={{ height: 50, width: 100, alignSelf: "end" }}
                disabled={loading}
                onClick={handleCreate}
              >
                {loading ? <CircularProgress color="warning" /> : "Create"}
              </Button>
            </FormControl>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

export default CreateMuscleType;
